/* eslint-disable prefer-destructuring */

import {
	Alert,
	Box,
	Button,
	Chip,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Tooltip,
	Typography
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MDBox from "components/Basics/MDBox";
import RefreshIcon from "@mui/icons-material/Refresh";
import MDTypography from "components/Basics/MDTypography";
import { useCallback, useEffect, useState } from "react";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import { selectAllSortedMenu } from "redux-react/reducers/profileReducer";
import _lod from "lodash";
import ReactJson from "react-json-view";
import { useDropzone } from "react-dropzone";
import "./style.css";
import { display } from "redux-react/reducers/snackBarReducer";
import MyDropzone from "./MyDropzone";

const FormDictionaryJSON = ({ submit }) => {
	const [name, setName] = useState("");
	const [code, setCode] = useState("");
	const [jsonData, setJsonData] = useState({});
	const [fileError, setFileError] = useState("");
	const pages = useSelector(selectAllSortedMenu);
	const [selectedPages, setSelectedPages] = useState(["/dictionnaires"]);
	const [showWarning, setShowWarning] = useState(false);

	const [successJSON, setSuccessJSON] = useState(true);

	useEffect(() => {
		if (code.trim() !== "" && name.trim() !== "") {
			submit({
				valid: true,
				data: {
					name,
					code,
					pages: selectedPages,
					jsonData
				}
			});
		} else {
			submit({
				valid: false
			});
		}
	}, [name, code, jsonData]);

	const checkCodeItem = text => {
		let regex = /^[A-Za-z]+$/;
		if (regex.test(text)) {
			setCode(text);
		} else if (text === "") {
			setCode(text);
		} else {
			setShowWarning(true);
			setTimeout(() => {
				setShowWarning(false);
			}, 3000);
		}
	};

	const onChangeInputs = e => {
		let value = e.updated_src;
		setJsonData(value);
	};

	return (
		<MDBox>
			<MDTypography variant="h6">{i18n.t("SETTINGS.DICTIONARY.newDictionary")}</MDTypography>
			<MDBox display="flex" flexDirection="column" mt={1}>
				<MDBox>
					<MDInput
						className="dialogInput"
						value={name}
						onChange={e => setName(e.target.value)}
						label={
							<span>
								{i18n.t("SETTINGS.DICTIONARY.dictionaryName")}
								<span className="mandatoryField">*</span>
							</span>
						}
					/>
				</MDBox>
				<MDBox mt={1}>
					<Tooltip
						open={showWarning}
						title={i18n.t("SETTINGS.RULES.regexCode")}
						placement="right"
						disableFocusListener
						disableHoverListener
						disableTouchListener
					>
						<MDInput
							className="dialogInput"
							value={code}
							onChange={e => checkCodeItem(e.target.value)}
							label={
								<span>
									{i18n.t("SETTINGS.DICTIONARY.dictionaryCode")}
									<span className="mandatoryField">*</span>
								</span>
							}
						/>
					</Tooltip>
				</MDBox>

				<Box sx={{ textAlign: "center", my: 4 }}>
					<Box>
						<Typography variant="h4" gutterBottom>
							{i18n.t("SETTINGS.DICTIONARY.jsonUpload")}
						</Typography>
						<MyDropzone
							setSuccessJSON={setSuccessJSON}
							setFileError={setFileError}
							setJsonData={setJsonData}
						/>
					</Box>

					{!successJSON && (
						<Alert severity="error" sx={{ mt: 2 }}>
							{fileError}
						</Alert>
					)}

					<Box sx={{ my: 3 }}>
						<Box
							style={{
								textAlign: "left"
							}}
						>
							<Tooltip title={i18n.t("SETTINGS.DICTIONARY.jsonReset")} placement="top">
								<IconButton
									style={{
										fontSize: "medium"
									}}
									disabled={_lod.isEmpty(jsonData)}
									onClick={() => setJsonData({})}
								>
									<RefreshIcon />
								</IconButton>
							</Tooltip>
						</Box>
						<ReactJson
							src={jsonData}
							name={null}
							style={{
								fontSize: "medium",
								textAlign: "left"
							}}
							enableClipboard={false}
							displayDataTypes={false}
							onEdit={onChangeInputs}
							onAdd={onChangeInputs}
							onDelete={onChangeInputs}
						/>
					</Box>
				</Box>
				{/* 
				<MDBox mt={1}>
					<FormControl fullWidth>
						<InputLabel id="select-pages-label">
							<span>
								{i18n.t("SETTINGS.DICTIONARY.dictionaryPages")}
								<span className="mandatoryField">*</span>
							</span>
						</InputLabel>
						<Select
							labelId="select-pages-label"
							id="select-pages"
							multiple
							value={selectedPages}
							onChange={e => setSelectedPages(e.target.value)}
							input={
								<OutlinedInput
									id="select-pages-chip"
									label={
										<span>
											{i18n.t("SETTINGS.DICTIONARY.dictionaryPages")}
											<span className="mandatoryField">*</span>
										</span>
									}
								/>
							}
							renderValue={selected => (
								<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
									{selected.map((page, index) => {
										let goodPage = pages.find(p => p.route === page);
										return <Chip key={index} label={goodPage.name} />;
									})}
								</Box>
							)}
						>
							{pages.map((page, index) => (
								<MenuItem key={index} value={page.route}>
									{page.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</MDBox> */}
			</MDBox>
		</MDBox>
	);
};

export default FormDictionaryJSON;
