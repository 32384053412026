/* eslint-disable object-shorthand */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */

/**
 * #######################################################@
 *
 * FORMS page
 *
 * #######################################################@
 */
import "./style.css";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Avatar,
	Box,
	Card,
	Checkbox,
	CircularProgress,
	ClickAwayListener,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	FormControl,
	FormControlLabel,
	FormGroup,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Popper,
	Select,
	Switch,
	Tooltip,
	Typography,
	Chip
} from "@mui/material";
import FormActions from "redux-react/actions/formAction";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { display } from "redux-react/reducers/snackBarReducer";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import useCopyToClipboard from "helpers/useCopyToClipboard";
import _lod from "lodash";
import { Add, Delete, ExpandMore } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useForm } from "hooks/useForm";
import axios from "axios";
import { regexPhoneNumber, regexMail } from "helpers/utilities";
import MDDatePicker from "components/Basics/MDDatePicker";
import MuiPhoneNumber from "mui-phone-number";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { getSignedUrlS3, uploadToSignedUrl, removeToSignedUrl } from "helpers/s3";
import MDTypography from "components/Basics/MDTypography";
import { ChromePicker, TwitterPicker } from "react-color";
import TagsList from "../TagList";

const useStyles = {
	numberCircle: {
		width: "30px",
		height: "30px",
		padding: "6px 0px",
		borderRadius: "15px",
		textAlign: "center",
		fontSize: "14px",
		lineHeight: 1.42857,
		backgroundColor: "#dfe6e9"
	}
};

let targetForm = "";

export default function FormDictionaryDialog({
	route,
	open,
	handleCloseDialog,
	handleSave,
	valuesDictionary,
	formBuildDictionary,
	contextDictionary,
	PIaccess,
	isEmpty,
	target,
	selectedId,
	routeDict,
	flagAccount,
	forceEdit = false,
	regexEmail,
	useLegacy,
	listAPIs,
	dbName = null
}) {
	let validate = () => {
		const errors = {};
		// validateData();
		return errors;
	};

	const [PI, setPI] = useState(PIaccess);
	const [formDictionary, setFormDictionary] = useState(formBuildDictionary);
	const [customErrors, setCustomErrors] = useState({});
	const [hasErrors, setHasErrors] = useState(false);
	const [confirmHasPI, setConfirmHasPI] = useState(false);
	const dispatch = useDispatch();
	const [customUnique, setCustomUnique] = useState({});
	const [loadingValidate, setLoadingValidate] = useState(false);
	const [rolesProfileUser, setRolesProfileUser] = useState([]);
	const [stringCode, setStringCode] = useState("");
	const [stringLabel, setStringLabel] = useState("");
	const [stringWarnAdd, setStringWarnAdd] = useState("");
	const [stringWarnCode, setStringWarnCode] = useState("");
	const [showWarning, setShowWarning] = useState(false);
	const [showWarningCode, setShowWarningCode] = useState(false);
	const [showWarningLabel, setShowWarningLabel] = useState(false);
	const [indexWarnCode, setIndexWarnCode] = useState(null);
	const [useLegacyDatabase, setUseLegacyDatabase] = useState(useLegacy);
	const { user } = useSelector(state => state);

	let { values, errors, setValues, handleInputChange } = useForm(valuesDictionary, true, validate);
	/**
	 * Check if there is errors in form to enable/disable validate button
	 */
	useEffect(() => {
		if (
			!_lod.isEmpty(customErrors) ||
			(_lod.isEmpty(rolesProfileUser) && target === "user" && isEmpty)
		) {
			setHasErrors(true);
		} else {
			setHasErrors(false);
		}
	}, [customErrors, rolesProfileUser]);

	function setListNotEmpty() {
		if (routeDict === "dictionary" && isEmpty) {
			return [];
		} else if (routeDict === "dictionary" && _lod.isArray(values?.codes) && !isEmpty) {
			return values?.codes;
		} else {
			return [];
		}
	}

	const [listCodes, setListCodes] = useState(setListNotEmpty());

	/**
	 * Setup form with get different config/info for route target
	 */
	useEffect(() => {
		targetForm = target;
	}, [route]);

	/**
	 * Change value with different condition and sand error if not agree with condition
	 */
	useEffect(() => {
		if (routeDict === "dictionary") {
			let e = { target: { name: "whitelist", value: [] } };
			handleInputChange(e);
			if (["boolean", "code", "level", "color", "picture"].includes(values?.type)) {
				let cloneValues = _lod.cloneDeep(values);
				cloneValues.isDynamic = false;
				delete cloneValues?.whitelistDynamic;
				setValues(cloneValues);
			}

			if (["labels"].includes(values?.type)) {
				let cloneValues = _lod.cloneDeep(values);
				_lod.set(cloneValues, "isDynamic", true);

				_lod.set(cloneValues, "whitelistDynamic", {});

				setValues(cloneValues);
			}
		}
	}, [values?.type]);

	useEffect(() => {
		if (routeDict === "dictionary") {
			let cloneValues = _lod.cloneDeep(values);

			if (values.isDynamic === false) {
				_lod.set(cloneValues, "whitelistDynamic", {});
			}
			setValues(cloneValues);
		}
	}, [values?.isDynamic]);

	const handleInputChangeValidate = async (e, value, child) => {
		// special handle for default value of dictionary

		if (child === "defaultValue" && routeDict === "dictionary") {
			value.type = e.defaultType;
			e = e.event;
		}

		if (value?.type === "boolean") {
			e = { target: { name: child, value: Boolean(e) } };
			handleInputChange(e);
			return;
		}

		if (child.includes(".value")) {
			child = child.replaceAll(".value", "");
			e.target.name = e.target.name.replaceAll(".value", "");
		}

		if (value?.blacklist) {
			if (value.blacklist.includes(e.target.value)) {
				setCustomErrors({ ...customErrors, [child]: i18n.t("FORMS.blackListCustomer") });
				setHasErrors(true);
			} else {
				let copyErrors = _lod.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			}
		}

		if (value?.whitelist) {
			if (
				value.whitelist.includes(e.target.value) ||
				e.target.value === "" ||
				value.whitelist.length === 0
			) {
				let copyErrors = _lod.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			} else {
				setCustomErrors({ ...customErrors, [child]: i18n.t("FORMS.whitelistContact") });
				setHasErrors(true);
			}
		}

		if (value?.unique) {
			if (e.target.value !== value.value) {
				setCustomUnique({ ...customUnique, [child]: e.target.value });
			} else {
				let copyUnique = _lod.cloneDeep(customUnique);
				delete copyUnique[child];
				setCustomUnique(copyUnique);
			}

			if (value?.type === "email" && !regexMail(e.target.value, regexEmail) && !value?.isArray) {
				setCustomErrors({
					...customErrors,
					[child]: i18n.t("FORMS.invalidEmail")
				});
			} else {
				let copyErrors = _lod.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
			}
		} else if (
			value?.type === "email" &&
			!value?.isArray &&
			!regexMail(e.target.value, regexEmail)
		) {
			setCustomErrors({
				...customErrors,
				[child]: i18n.t("FORMS.invalidEmail")
			});
		} else {
			let copyErrors = _lod.cloneDeep(customErrors);
			delete copyErrors[child];
			setCustomErrors(copyErrors);
		}

		if (value?.type === "mdp") {
			const password = e.target.value;

			const AtLeast8chars = password.length > 7;

			const AtLeastOneLower = /[a-z]/.test(password);

			const AtLeastOneUpper = /[A-Z]/.test(password);

			const AtLeastOneNumber = /[0-9]/.test(password);

			const AtLeastOneSpecial = /[-+_!@#$%^&*.,?]/.test(password);

			const passwordIsStrong =
				AtLeast8chars &&
				AtLeastOneLower &&
				AtLeastOneUpper &&
				AtLeastOneNumber &&
				AtLeastOneSpecial;

			const lengthValue = i18n.t("FORMS.psswordStrength.length");
			const oneLower = i18n.t("FORMS.psswordStrength.lowercaseLetter");
			const oneUpper = i18n.t("FORMS.psswordStrength.capitalLetter");
			const oneNumber = i18n.t("FORMS.psswordStrength.number");
			const oneSpecial = i18n.t("FORMS.psswordStrength.specialChar");

			const validateMessage = (
				<MDBox borderRadius="lg" p={3}>
					<MDBox>
						<MDTypography display="inline" variant="h6" fontWeight="bold">
							{i18n.t("FORMS.psswordStrength.message")}
						</MDTypography>
						<MDBox>
							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeast8chars ? "red" : "green" }}
							>
								{lengthValue} {!AtLeast8chars ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>

							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeastOneLower ? "red" : "green" }}
							>
								{oneLower} {!AtLeastOneLower ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>

							<MDTypography
								variant="body2"
								component="p"
								style={{ color: !AtLeastOneUpper ? "red" : "green" }}
								alignItems="center"
								display="flex"
							>
								{oneUpper} {!AtLeastOneUpper ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>

							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeastOneNumber ? "red" : "green" }}
							>
								{oneNumber} {!AtLeastOneNumber ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>
							<MDTypography
								variant="body2"
								component="p"
								alignItems="center"
								display="flex"
								style={{ color: !AtLeastOneSpecial ? "red" : "green" }}
							>
								{oneSpecial} {!AtLeastOneSpecial ? <Icon>close </Icon> : <Icon>check </Icon>}
							</MDTypography>
						</MDBox>
					</MDBox>
				</MDBox>
			);

			if (!passwordIsStrong) {
				setCustomErrors({ ...customErrors, [child]: validateMessage });
				setHasErrors(true);
			} else {
				let copyErrors = _lod.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
				setHasErrors(false);
			}
		}

		if (value?.type === "date") {
			e = { target: { name: child, value: new Date(e[0]) } };
		}

		if (value?.type === "number") {
			if (!value?.isArray) {
				e = { target: { name: child, value: parseFloat(e.target.value) } };
			}
		}

		if (value?.type === "picture") {
			if (e.type === "change") {
				if (e.target.files[0].type.startsWith("image/")) {
					const result = await getSignedUrlS3(e.target.files[0], target);

					await uploadToSignedUrl(result.signedURL, e.target.files[0]);
					e = {
						target: {
							name: child,
							value: result.accessURL
						}
					};
					let copyErrors = _lod.cloneDeep(customErrors);
					delete copyErrors[child];
					setCustomErrors(copyErrors);
				} else {
					setCustomErrors({
						...customErrors,
						[child]: i18n.t("FORMS.invalidPicture")
					});
					e = { target: { name: child, value: "" } };
				}
			} else {
				await removeToSignedUrl(values[child], target);
				e = { target: { name: child, value: "" } };
			}
		}

		if (value?.type === "phone") {
			e = { target: { name: child, value: e } };
			e.target.value = e.target.value.replace(/\s/g, "");
			if (!regexPhoneNumber(e.target.value) && !_lod.isEmpty(e.target.value)) {
				setCustomErrors({ ...customErrors, [child]: i18n.t("FORMS.invalidPhone") });
			} else {
				let copyErrors = _lod.cloneDeep(customErrors);
				delete copyErrors[child];
				setCustomErrors(copyErrors);
			}
		}

		handleInputChange(e);
	};

	function close(e, reason) {
		handleCloseDialog();
	}

	useEffect(() => {
		if (routeDict === "dictionary" && values?.type === "code") {
			let cloneValues = _lod.cloneDeep(values);
			if (!_lod.isEmpty(listCodes)) {
				cloneValues.codes = listCodes;
			} else {
				cloneValues.codes = {};
			}
			setValues(cloneValues);
		}
	}, [listCodes]);

	/**
	 * Send form
	 */
	function sumbit() {
		handleSave(
			values,
			customUnique,
			callback => {
				setLoadingValidate(false);
			},
			rolesProfileUser
		);
	}

	const convertToDefEventParam = (name, value) => ({
		target: {
			name,
			value
		}
	});

	/**
	 * Gestion remove for array in form
	 */
	const handleRemoveProduct = (index, path, e, pathDict) => {
		e.stopPropagation();
		_lod.get(values, pathDict).splice(index, 1);
		const cloneDict = _lod.cloneDeep(formDictionary);
		_lod.get(cloneDict, path).splice(index, 1);
		setFormDictionary(cloneDict);
		handleInputChange(convertToDefEventParam(path, _lod.get(values, path)));
	};

	/**
	 * Gestion add for array in form
	 */
	const handleAddProduct = (path, pathDict) => {
		let formatDict = contextDictionary;
		let pathFormat = path;
		let pathDirect = path;
		const cloneDict = _lod.cloneDeep(formDictionary);

		if (pathFormat.includes(".value")) {
			pathFormat = pathFormat.replaceAll(".value", "");
		}

		if (pathFormat.includes(".")) {
			pathFormat = pathFormat.replaceAll(".", ".items.") + ".items";
		} else {
			pathFormat += ".items";
		}
		const newFormat =
			_lod.get(formatDict, pathFormat) || _lod.get(formatDict, pathDirect + ".items");

		let valueFormat = {};
		let dictFormat = {};
		for (let childFormat in newFormat) {
			if (newFormat[childFormat].active) {
				dictFormat[childFormat] = {
					type: newFormat[childFormat].type,
					display: newFormat[childFormat].display,
					isEdit: newFormat[childFormat]?.actions?.edit || true,
					...newFormat[childFormat].label,
					value: null
				};
				valueFormat[childFormat] = null;
			}
		}

		_lod.get(values, pathDict).push({ ...valueFormat });
		_lod.get(cloneDict, path).push({ ...dictFormat });

		setFormDictionary(cloneDict);

		handleInputChange(convertToDefEventParam(path, _lod.get(values, pathDict)));
	};

	/**
	 * If u have PI in form and u click to show them
	 */
	const setPIForm = () => {
		setConfirmHasPI(false);
		const onSuccess = res => {
			setValues(res.valueDictionary);
			setFormDictionary(res.formBuilderDictionary);
			setPI(true);
		};
		if (target) {
			dispatch(
				FormActions.getItemByID(
					selectedId,
					target,
					routeDict,
					onSuccess,
					useLegacyDatabase,
					dbName,
					true,
					route.form.catalog
				)
			);
		}
	};

	const setOpenConfirmPI = () => {
		if (values?.hasPIValue) {
			setConfirmHasPI(true);
		} else {
			setPIForm();
		}
	};

	const handleClosePI = () => {
		setConfirmHasPI(false);
	};

	useEffect(() => {
		if (forceEdit && selectedId) {
			setOpenConfirmPI();
		}
	}, [forceEdit]);

	const testStringWhithSpace = str => {
		let chaineSansEspaces = str.trim();
		return chaineSansEspaces === "";
	};

	const addCodes = () => {
		if (!testStringWhithSpace(stringCode) && !testStringWhithSpace(stringLabel)) {
			if (!listCodes.find(objet => objet.value === stringCode)) {
				let cloneListCodes = _lod.cloneDeep(listCodes);
				cloneListCodes.push({ value: stringCode, label: stringLabel });
				setListCodes(cloneListCodes);
				setStringCode("");
				setStringLabel("");
			} else {
				setShowWarning(true);
				setStringWarnAdd(i18n.t("FORMS.uniqueCode"));

				setTimeout(() => {
					setShowWarning(false);
				}, 3000);
			}
		} else {
			setShowWarning(true);
			setStringWarnAdd(i18n.t("FORMS.labelOrCodeRequired"));
			setTimeout(() => {
				setShowWarning(false);
			}, 3000);
		}
	};

	const deleteCodes = index => {
		let cloneListCodes = _lod.cloneDeep(listCodes);
		cloneListCodes.splice(index, 1);
		setListCodes(cloneListCodes);
	};

	const updateCodes = (index, value) => {
		let cloneListCodes = _lod.cloneDeep(listCodes);
		if (!listCodes.find(objet => objet.value === value)) {
			if (!testStringWhithSpace(value)) {
				cloneListCodes[index].value = value;
				setListCodes(cloneListCodes);
			} else {
				setIndexWarnCode(index);
				setShowWarningCode(true);
				setStringWarnCode(i18n.t("FORMS.codeRequired"));
				setTimeout(() => {
					setIndexWarnCode(null);
					setShowWarningCode(false);
					setShowWarning(false);
				}, 3000);
			}
		} else {
			setIndexWarnCode(index);
			setShowWarningCode(true);
			setStringWarnCode(i18n.t("FORMS.uniqueCode"));
			setTimeout(() => {
				setIndexWarnCode(null);
				setShowWarningCode(false);
				setShowWarning(false);
			}, 3000);
		}
	};

	const updateLabel = (index, value) => {
		if (!testStringWhithSpace(value)) {
			let cloneListCodes = _lod.cloneDeep(listCodes);
			cloneListCodes[index].label = value;
			setListCodes(cloneListCodes);
		} else {
			setIndexWarnCode(index);
			setShowWarningLabel(true);
			setTimeout(() => {
				setIndexWarnCode(null);
				setShowWarningLabel(false);
				setShowWarning(false);
			}, 3000);
		}
	};

	const updateDynamic = e => {
		handleInputChange(e);
	};

	let onChangeDynamicTag = (child, list) => {
		let e = { target: { name: child, value: list } };
		handleInputChange(e);
	};

	return (
		<Dialog
			fullWidth
			maxWidth={
				routeDict === "dictionary" && (values?.type === "code" || values?.isDynamic === true)
					? "lg"
					: "md"
			}
			open={open}
			onClose={close}
		>
			<Box style={{ display: "flex" }}>
				<DialogTitle>
					{`${isEmpty ? i18n.t("FORMS.LABELS.add") : i18n.t("FORMS.LABELS.update")} ${
						route?.form?.pageLabel ?? route.name
					}`}
				</DialogTitle>
				{!PI && (
					<Tooltip
						title={`${i18n.t("FORMS.edit")} ${route?.form?.pageLabel ?? route.name}`}
						placement="top"
					>
						<IconButton disabled={PI} onClick={() => setOpenConfirmPI()}>
							<EditIcon />
						</IconButton>
					</Tooltip>
				)}
			</Box>
			<DialogContent>
				{confirmHasPI && (
					<Dialog open={confirmHasPI} onClose={handleClosePI}>
						<DialogTitle>{i18n.t("FORMS.showHiddenConfirmTitle")}</DialogTitle>
						<DialogContent>{i18n.t("FORMS.showHiddenConfirm")}</DialogContent>
						<DialogActions>
							<MDButton autoFocus onClick={handleClosePI} variant="outlined" color="info">
								{i18n.t("FORMS.cancel")}
							</MDButton>
							<MDButton onClick={setPIForm} color="info" variant="contained" autoFocus>
								{i18n.t("FORMS.validate")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}

				<MDBox display="flex">
					<MDBox
						display="flex"
						flexDirection="column"
						width={
							routeDict === "dictionary" && (values?.type === "code" || values?.isDynamic === true)
								? "60%"
								: "100%"
						}
					>
						<OrderFormDictionaryChild
							fieldChild={formDictionary}
							regexEmail={regexEmail}
							listAPIs={listAPIs}
							isEmpty={isEmpty}
							values={values}
							PI={PI}
							formDictionary={formDictionary}
							customErrors={customErrors}
							userLangage={user?.language?.toLowerCase() || "fr"}
							root=""
							handleInputChangeValidate={handleInputChangeValidate}
							handleRemoveProduct={handleRemoveProduct}
							handleAddProduct={handleAddProduct}
							contextDictionary={contextDictionary}
							routeDict={routeDict}
						/>
					</MDBox>
					{routeDict === "dictionary" && values?.type === "code" && (
						<MDBox display="flex" width="40%">
							<hr style={{ borderLeft: "1px solid #ccc", height: "100%", margin: "0 10px" }} />
							<MDBox display="flex" flexDirection="column" width="100%">
								<MDTypography variant="h6">{i18n.t("FORMS.addCode")}</MDTypography>
								<MDBox display="flex" width="100%" alignItems="center" pt={1}>
									<MDBox width="45%" pr={1}>
										<MDInput
											label="Code"
											value={stringCode}
											onChange={e => setStringCode(e.target.value)}
										/>
									</MDBox>
									<MDBox width="45%">
										<MDInput
											label="Label"
											value={stringLabel}
											onChange={e => setStringLabel(e.target.value)}
										/>
									</MDBox>
									<MDBox pl={1}>
										<Tooltip
											open={showWarning}
											title={stringWarnAdd}
											placement="right"
											disableFocusListener
											disableHoverListener
											disableTouchListener
										>
											<IconButton size="small" onClick={() => addCodes()}>
												<Add />
											</IconButton>
										</Tooltip>
									</MDBox>
								</MDBox>

								<MDBox pt={1}>
									<MDTypography variant="h6">{i18n.t("FORMS.listCodes")}</MDTypography>
									{_lod.isEmpty(listCodes) && (
										<MDTypography variant="h6">{i18n.t("FORMS.emptyCodes")}</MDTypography>
									)}
									{listCodes.map((value, index) => (
										<MDBox display="flex" width="100%" alignItems="center" pt={1}>
											<MDBox width="45%" pr={1}>
												<Tooltip
													open={showWarningCode && index === indexWarnCode}
													title={stringWarnCode}
													placement="bottom"
													disableFocusListener
													disableHoverListener
													disableTouchListener
												>
													<MDInput
														label="Code"
														variant="standard"
														value={value.value}
														onChange={e => updateCodes(index, e.target.value)}
														readOnly
													/>
												</Tooltip>
											</MDBox>
											<MDBox width="45%">
												<Tooltip
													open={showWarningLabel && index === indexWarnCode}
													title={i18n.t("FORMS.labelRequired")}
													placement="bottom"
													disableFocusListener
													disableHoverListener
													disableTouchListener
												>
													<MDInput
														label="Label"
														variant="standard"
														value={value.label}
														onChange={e => updateLabel(index, e.target.value)}
														readOnly
													/>
												</Tooltip>
											</MDBox>
											<MDBox pl={1}>
												<IconButton size="small" onClick={() => deleteCodes(index)}>
													<Delete />
												</IconButton>
											</MDBox>
										</MDBox>
									))}
								</MDBox>
							</MDBox>
						</MDBox>
					)}
					{routeDict === "dictionary" && values?.isDynamic === true && (
						<MDBox display="flex" width="40%">
							<hr style={{ borderLeft: "1px solid #ccc", height: "100%", margin: "0 10px" }} />
							<MDBox display="flex" flexDirection="column" width="100%">
								<MDTypography variant="h6">{i18n.t("FORMS.addDynamic")}</MDTypography>
								<FormControl style={{ marginTop: "2%" }}>
									<InputLabel id="select-label">{i18n.t("FORMS.apiName")}</InputLabel>
									<Select
										labelId="select-label"
										id="select"
										name="whitelistDynamic.apiName"
										value={values?.whitelistDynamic?.apiName || ""}
										onChange={updateDynamic}
									>
										{listAPIs
											?.sort((a, b) => a.name.localeCompare(b.name))
											?.map((api, index) => (
												<MenuItem key={index} value={api.name}>
													{api.name}
												</MenuItem>
											))}
									</Select>
								</FormControl>

								<FormControl style={{ marginTop: "2%" }}>
									<InputLabel id="select-label">{i18n.t("FORMS.actionAPI")}</InputLabel>
									<Select
										labelId="select-label"
										id="select"
										name="whitelistDynamic.action"
										value={values?.whitelistDynamic?.action || ""} /// Par défaut on laisse getRessourceWithQuery
										onChange={updateDynamic}
									>
										{Object.keys(
											listAPIs.find(item => item.name === values?.whitelistDynamic?.apiName)?.config
												?.action || {}
										).map((apiName, index) => (
											<MenuItem key={index} value={apiName}>
												{apiName}
											</MenuItem>
										))}
									</Select>
								</FormControl>

								<Accordion defaultExpanded className="customAccordion" style={{ marginTop: "2%" }}>
									<AccordionSummary
										expandIcon={<ExpandMore />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										{i18n.t("FORMS.pramsAPI")}
									</AccordionSummary>
									<AccordionDetails>
										<MDInput
											name="whitelistDynamic.params.collection"
											className="dialogInput"
											label={i18n.t("FORMS.collectionAPI")}
											key="0"
											value={values?.whitelistDynamic?.params?.collection || ""}
											disabled={!PI}
											onChange={updateDynamic}
										/>
										<MDInput
											style={{ marginTop: "2%" }}
											name="whitelistDynamic.params.attribute"
											className="dialogInput"
											label={i18n.t("FORMS.attributeAPI")}
											key="0"
											value={values?.whitelistDynamic?.params?.attribute || ""}
											disabled={!PI}
											onChange={updateDynamic}
										/>

										<Autocomplete
											value={
												_lod.isArray(values?.whitelistDynamic?.params?.searchFields)
													? values?.whitelistDynamic?.params?.searchFields
													: []
											}
											onChange={(e, newValue) => {
												onChangeDynamicTag("whitelistDynamic.params.searchFields", newValue);
											}}
											style={{ marginTop: "2%" }}
											readOnly={!PI}
											multiple
											id="tags-filled"
											options={[]}
											freeSolo
											renderTags={(tags, getTagProps) => {
												return tags.map((option, index) => {
													return <Chip label={option} {...getTagProps({ index })} />;
												});
											}}
											renderInput={params => (
												<MDInput {...params} label={i18n.t("FORMS.searchFieldsAPI")} />
											)}
										/>
									</AccordionDetails>
								</Accordion>
							</MDBox>
						</MDBox>
					)}
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={close}>
					{i18n.t("FORMS.cancel")}
				</MDButton>
				<MDButton
					variant="contained"
					color="info"
					onClick={sumbit}
					disabled={loadingValidate || hasErrors || !PI}
				>
					{i18n.t("FORMS.validate")}
					{loadingValidate && (
						<Fade in={loadingValidate}>
							<MDBox display="flex" alignItems="center" ml={2}>
								<CircularProgress size={20} color="light" />
							</MDBox>
						</Fade>
					)}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}

function OrderFormDictionaryChild({
	contextDictionary,
	fieldChild,
	values,
	handleInputChangeValidate,
	userLangage,
	root,
	formDictionary,
	handleRemoveProduct,
	handleAddProduct,
	customErrors,
	PI,
	isEmpty,
	routeDict,
	regexEmail,
	listAPIs
}) {
	const classes = useStyles;

	const scrollBottom = useRef(null);

	function addProduc(rootPath, root) {
		// handleAddProduct(rootPath, root);
		// scrollBottom.current.scrollIntoView({ behavior: "smooth" });
	}

	return (
		<>
			{Object.keys(fieldChild)
				.sort((a, b) => fieldChild[a].order - fieldChild[b].order)
				.map((child, key) => {
					let rootPath = root === "" ? `${child}` : root + `.${child}`;
					if (fieldChild[child].type !== "level" && typeof fieldChild[child] === "object") {
						let formConfig = _lod.get(formDictionary, rootPath);
						if (Array.isArray(fieldChild[child])) {
							// return (
							// 	<>
							// 		{targetForm !== "profile" && (
							// 			<MDBox mb={1}>
							// 				<MDButton
							// 					size="small"
							// 					style={{ height: "100%", marginRight: "0.75rem" }}
							// 					variant="contained"
							// 					color="info"
							// 					onClick={() => addProduc(rootPath, root)}
							// 					disabled={!PI}
							// 				>
							// 					<Icon>add</Icon>&nbsp;{i18n.t("FORMS.addProduct")}
							// 				</MDButton>
							// 			</MDBox>
							// 		)}
							// 		<ChildAccordionMapper
							// 			child={fieldChild[child]}
							// 			isEmpty={isEmpty}
							// 			classes={classes}
							// 			values={values}
							// 			root={root}
							// 			handleRemoveProduct={handleRemoveProduct}
							// 			rootPath={rootPath}
							// 			PI={PI}
							// 			userLangage={userLangage}
							// 			customErrors={customErrors}
							// 			handleInputChangeValidate={handleInputChangeValidate}
							// 			routeDict={routeDict}
							// 		/>
							// 		<div ref={scrollBottom}></div>
							// 	</>
							// );
						} else {
							return (
								<Box key={key} style={{ padding: "0em 0.5em 1em 0.5em" }}>
									<InpuType
										regexEmail={regexEmail}
										listAPIs={listAPIs}
										child={rootPath}
										whitelistDynamic={formConfig?.whitelistDynamic}
										isArray={formConfig?.isArray}
										whitelist={formConfig?.whitelist}
										isDisplay={formConfig?.display}
										codes={formConfig?.codes}
										label={fieldChild[child][userLangage]}
										isEdit={fieldChild[child]?.isEdit}
										key={key}
										PI={PI}
										isEmpty={isEmpty}
										type={formConfig?.type}
										valueForm={values}
										value={_lod.get(values, rootPath)}
										customErrors={_lod.get(customErrors, rootPath)}
										onChange={e => handleInputChangeValidate(e, formConfig, rootPath)}
										values={values}
										routeDict={routeDict}
									></InpuType>
								</Box>
							);
						}
					}

					if (
						fieldChild[child].type === "level" &&
						typeof fieldChild[child] === "object" &&
						!fieldChild[child].isArray
					) {
						let label = fieldChild[child][userLangage];
						if (typeof label === "object") {
							label = child;
						}
						return (
							<Accordion style={{ margin: "0em 0.5em 1em " }} className="customAccordion" key={key}>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									{label}
								</AccordionSummary>
								<AccordionDetails>
									<OrderFormDictionaryChild
										fieldChild={fieldChild[child]}
										isEmpty={isEmpty}
										userLangage={userLangage}
										root={rootPath}
										values={values}
										key={key}
										PI={PI}
										contextDictionary={contextDictionary[child]}
										handleInputChangeValidate={handleInputChangeValidate}
										formDictionary={formDictionary}
										handleRemoveProduct={handleRemoveProduct}
										handleAddProduct={handleAddProduct}
										customErrors={customErrors}
									></OrderFormDictionaryChild>
								</AccordionDetails>
							</Accordion>
						);
					} else if (fieldChild[child].type === "level" && fieldChild[child].isArray) {
						let label = fieldChild[child][userLangage];

						return (
							<Accordion style={{ margin: "0em 0.5em 1em " }} className="customAccordion" key={key}>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									{label}
								</AccordionSummary>
								<AccordionDetails>
									<MDBox mb={1}>
										<MDButton
											size="small"
											style={{ height: "100%", marginRight: "0.75rem" }}
											variant="contained"
											color="info"
											onClick={() => addProduc(rootPath, rootPath)}
											disabled={!PI}
										>
											<Icon>add</Icon>&nbsp;{i18n.t("FORMS.addProduct")}
										</MDButton>
									</MDBox>
									{fieldChild[child].value.map((childArray, key) => {
										let rootArray = rootPath + `[${key}]`;

										let rootArrayDico = rootPath + `.value[${key}]`;

										return (
											<ChildAccordionArrayMapper
												fieldChild={fieldChild[child].value[key]}
												index={key}
												contextDictionary={contextDictionary}
												isEmpty={isEmpty}
												title={fieldChild[child][userLangage]}
												classes={classes}
												values={values}
												root={rootArray}
												handleRemoveProduct={handleRemoveProduct}
												rootPath={rootArrayDico}
												PI={PI}
												formDictionary={formDictionary}
												userLangage={userLangage}
												customErrors={customErrors}
												handleAddProduct={addProduc}
												handleInputChangeValidate={handleInputChangeValidate}
												routeDict={routeDict}
											/>
										);
									})}
									<div ref={scrollBottom}></div>
								</AccordionDetails>
							</Accordion>
						);
					}
				})}
		</>
	);
}

const ChildAccordionArrayMapper = ({
	fieldChild,
	classes,
	values,
	handleRemoveProduct,
	root,
	rootPath,
	PI,
	userLangage,
	customErrors,
	handleInputChangeValidate,
	isEmpty,
	formDictionary,
	handleAddProduct,
	routeDict,
	contextDictionary,
	index,
	title
}) => {
	return (
		<Accordion style={{ margin: "0em 0.5em 1em " }} className="customAccordion" key={index}>
			<AccordionSummary
				expandIcon={<ExpandMore />}
				aria-controls="panel1a-content"
				id="panel1a-header"
			>
				{!_lod.isNil(index) && (
					<Box style={{ flex: "0 0 50px" }}>
						<div className={classes.numberCircle}>{index + 1}</div>
					</Box>
				)}

				<Typography>
					<span style={{ fontWeight: "bolder" }}>{title}</span>
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{Object.keys(fieldChild)
					.sort((a, b) => fieldChild[a].order - fieldChild[b].order)
					.map((child, key) => {
						if (
							fieldChild[child].type === "level" &&
							fieldChild[child].isArray &&
							typeof fieldChild[child] === "object"
						) {
							return (
								<>
									{fieldChild[child].value.map((childArray, keyArray) => {
										let rootPathArray = root === "" ? `${root}` : root + `.${child}[${keyArray}]`;
										let rootPathArrayDico =
											rootPath === "" ? `${rootPath}` : rootPath + `.${child}.value[${keyArray}]`;

										return (
											<ChildAccordionArrayMapper
												fieldChild={fieldChild[child].value[keyArray]}
												index={keyArray}
												contextDictionary={contextDictionary}
												isEmpty={isEmpty}
												title={fieldChild[child][userLangage]}
												classes={classes}
												values={values}
												root={rootPathArray}
												handleRemoveProduct={handleRemoveProduct}
												rootPath={rootPathArrayDico}
												PI={PI}
												formDictionary={formDictionary}
												userLangage={userLangage}
												customErrors={customErrors}
												handleAddProduct={handleAddProduct}
												handleInputChangeValidate={handleInputChangeValidate}
												routeDict={routeDict}
											/>
										);
									})}
								</>
							);
						} else if (
							fieldChild[child].type === "level" &&
							!fieldChild[child].isArray &&
							typeof fieldChild[child] === "object"
						) {
							let rootPathArray = root === "" ? `${root}` : root + `.${child}`;
							let rootPathArrayDico = rootPath === "" ? `${rootPath}` : rootPath + `.${child}`;
							return (
								<ChildAccordionArrayMapper
									fieldChild={fieldChild[child]}
									title={fieldChild[child][userLangage]}
									classes={classes}
									isEmpty={isEmpty}
									userLangage={userLangage}
									root={rootPathArray}
									rootPath={rootPathArrayDico}
									values={values}
									keyArray={key}
									PI={PI}
									handleInputChangeValidate={handleInputChangeValidate}
									formDictionary={formDictionary}
									handleRemoveProduct={handleRemoveProduct}
									handleAddProduct={handleAddProduct}
									customErrors={customErrors}
								></ChildAccordionArrayMapper>
							);
						} else if (
							fieldChild[child].type !== "level" &&
							typeof fieldChild[child] === "object"
						) {
							let rootPathArrayDico = rootPath === "" ? `${child}` : rootPath + `.${child}`;
							let rootPathArray = root === "" ? `${child}` : root + `.${child}`;
							let formConfig = _lod.get(formDictionary, rootPathArrayDico);
							return (
								<Box key={key} style={{ padding: "0em 0.5em 1em 0.5em" }}>
									<InpuType
										regexEmail=""
										listAPIs={[]}
										child={rootPathArray}
										whitelistDynamic={formConfig?.whitelistDynamic}
										isArray={formConfig?.isArray}
										whitelist={formConfig?.whitelist}
										isDisplay={formConfig?.display}
										codes={formConfig?.codes}
										label={fieldChild[child][userLangage]}
										isEdit={fieldChild[child]?.isEdit}
										key={key}
										PI={PI}
										isEmpty={isEmpty}
										type={formConfig?.type}
										valueForm={values}
										value={_lod.get(values, rootPathArray)}
										customErrors={_lod.get(customErrors, rootPathArray)}
										onChange={e => handleInputChangeValidate(e, formConfig, rootPathArray)}
										values={values}
										routeDict={routeDict}
									></InpuType>
								</Box>
							);
						}
					})}
			</AccordionDetails>
		</Accordion>
	);
};

const BooleanInput = ({ value, PI, label, onChange }) => {
	const [checked, setChecked] = useState(Boolean(value));

	const handleCheck = e => {
		if (PI) {
			setChecked(!checked);
			onChange(!checked);
		}
	};

	// init value for first render
	useEffect(() => {
		onChange(checked);
	}, []);

	return (
		<MDBox
			mt={1}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			className="customSwitchContainer"
			onClick={e => handleCheck(e)}
		>
			<span>{label}</span>
			<MDBox>
				<Switch checked={checked} />
			</MDBox>
		</MDBox>
	);
};

const InpuType = ({
	styleChild,
	codes,
	child,
	key,
	label,
	value,
	onChange,
	customErrors,
	type,
	PI,
	isEdit,
	isEmpty,
	whitelist = false,
	whitelistDynamic = false,
	values,
	routeDict,
	isDisplay,
	valueForm,
	isArray = false,
	regexEmail,
	listAPIs
}) => {
	const [, handleCopy] = useCopyToClipboard(2000);
	const dispatch = useDispatch();
	const assistantIDs = useSelector(state => selectCurrentProfile(state).assistantID);
	const { user } = useSelector(state => state);
	const [selectedValue, setSelectedValue] = useState(value || []);
	const [viewInvalidEmail, setViewInvalidEmail] = useState("");

	const crmBackEndpoint = process.env.REACT_APP_AMBACK;
	const defaultImage = "https://cdn.pixabay.com/photo/2016/08/31/11/54/user-1633249_960_720.png"; // A DELETE

	const [valueAuto, setValueAuto] = useState(value);
	const [optionAuto, setOptionAuto] = useState([]);

	const [showPassword, setShowPassword] = useState(false);

	const [viewInvalid, setViewInvalid] = useState("");

	const [addCodeCountry, setCodeAddCountry] = useState("");
	const [addValueCodeCountry, setValueCodeAddCountry] = useState("");
	const [addLabelCountry, setLabelAddCountry] = useState("");

	const [stringWarnCode, setStringWarnCode] = useState("");
	const [showWarning, setShowWarning] = useState(false);

	const [showLabelWarning, setShowLabelWarning] = useState(false);
	const [indexWarnCode, setIndexWarnCode] = useState("");

	const [valueAutoCountry, setValueAutoCountry] = useState("");

	const [color, setColor] = useState({ hex: value || "#222" });
	const [pickerRef, setPickerRef] = useState(null);

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	let onChangeGate = e => {
		onChange(e);
	};

	let onChangeGateCountry = (childValue, target) => {
		if (childValue === "") {
			setShowLabelWarning(true);
			setIndexWarnCode(target);
			setTimeout(() => {
				setShowLabelWarning(false);
				setIndexWarnCode("");
			}, 3000);
		} else {
			let event = { target: { name: child + "." + target, value: childValue } };
			onChangeGate(event);
		}
	};

	let onChangeGateSkills = (list, type, child) => {
		let e = { target: { name: child, value: list } };
		onChange(e, { value: list, type }, child);
	};

	let onChangeGateTags = (list, type, child) => {
		let e = { target: { name: child, value: list } };
		onChange(e, { value: list, type }, child);
	};

	let onChangeGateArray = (list, type, child) => {
		let e = { target: { name: child, value: list } };
		onChange(e, { value: list, type, isArray: true }, child);
	};

	if (PI && !isEdit && type !== "chip") {
		PI = false;
	}

	async function loadSuggestions(
		searchText,
		collection,
		searchFields,
		apiName,
		action,
		limit = 10
	) {
		let querryData = [];
		searchFields.forEach(field => {
			const query = {};
			query[field] = { $regex: searchText };
			querryData.push(query);
		});

		let attribute = whitelistDynamic?.params?.attribute;

		querryData.push({ [attribute]: { $regex: searchText } });

		let dataQuerry = JSON.stringify({
			query: {
				$or: querryData
			}
		});

		let APIFind;
		let actionFind;
		if (apiName && action) {
			APIFind = listAPIs.find(item => item.name === apiName) || {};
			actionFind = APIFind.config.action[action];
		} else {
			return [];
		}

		let config = {
			method: actionFind.method,
			maxBodyLength: Infinity,
			url: `${APIFind.config.host}/api/v1/ressource/${collection}/items?search=${searchText}&page=1&limit=${limit}`,
			headers: {
				Authorization: `Bearer ${user.token}`,
				assistantID: assistantIDs,
				"Content-Type": "application/json"
			},
			data: dataQuerry
		};

		let result;
		await axios
			.request(config)
			.then(response => {
				result = response.data;
			})
			.catch(error => {
				// console.log(error);
			});
		return result?.results || [];
	}

	const copyValue = (valueCopy, byPass = true) => {
		if (!PI && byPass) {
			handleCopy(valueCopy);
			dispatch(
				display({
					message: i18n.t("FORMS.copyToClipboard"),
					type: "success"
				})
			);
		}

		// setSnackbarStatus({ open: true, message: `identifiant copié ` });
	};

	const getOptionLabel = option => {
		let labelAttribute = option[whitelistDynamic?.params?.attribute] || "";
		let label = whitelistDynamic.params.searchFields.map(key => option[key]).join(" - ");
		label = labelAttribute + " - " + label;
		return label;
	};

	const onChangeCRM = (event, newValue, option) => {
		if (option === "selectOption") {
			setValueAuto(newValue[whitelistDynamic.params.attribute]);
			event = { target: { name: child, value: newValue[whitelistDynamic.params.attribute] } };
			onChangeGate(event);
		} else if (option === "clear") {
			setValueAuto("");
			event = { target: { name: child, value: null } };
			onChangeGate(event);
		}
	};

	const onChangeCountry = (event, newValue, option) => {
		if (option === "selectOption") {
			setCodeAddCountry(newValue[whitelistDynamic.params.attribute]);
			setValueCodeAddCountry(newValue[whitelistDynamic.params.attribute]);
		} else if (option === "clear") {
			setCodeAddCountry("");
			setValueCodeAddCountry("");
		}
	};

	const ValueChange = async valueC => {
		setValueAuto(valueC);

		let result = await loadSuggestions(
			valueC,
			whitelistDynamic.params.collection,
			whitelistDynamic.params.searchFields,
			whitelistDynamic.apiName,
			whitelistDynamic.action
		);

		setOptionAuto(result);
	};

	const ValueChangeCountry = async valueC => {
		setCodeAddCountry(valueC);
		let result = await loadSuggestions(
			valueC,
			whitelistDynamic.params.collection,
			whitelistDynamic.params.searchFields,
			whitelistDynamic.apiName,
			whitelistDynamic.action
		);

		setOptionAuto(result);
	};

	const handleImageChange = e => {
		e.preventDefault();
		let reader = new FileReader();
		let newFile = e.target.files[0];
		reader.onloadend = () => {
			onChangeGate(e);
		};
		if (newFile) {
			reader.readAsDataURL(newFile);
		}
	};

	function displayPicker(e) {
		setPickerRef(e.target);
	}

	function handleColorSelection(color) {
		if (PI) {
			setColor(color);
			let event = { target: { name: child, value: color.hex } };
			onChangeGate(event);
		}
	}

	const deleteLabel = target => {
		let copyValue = _lod.cloneDeep(value);

		delete copyValue[target];

		let event = { target: { name: child, value: copyValue } };

		onChangeGate(event);
	};

	const addLabel = () => {
		if (addLabelCountry !== "" && addValueCodeCountry !== "") {
			if (_lod.get(value, addValueCodeCountry)) {
				setShowWarning(true);
				setStringWarnCode(i18n.t("FORMS.uniqueCodeKey"));
				setTimeout(() => {
					setShowWarning(false);
				}, 3000);
			} else {
				let event = { target: { name: child + "." + addValueCodeCountry, value: addLabelCountry } };
				onChangeGate(event);
				setCodeAddCountry("");
				setLabelAddCountry("");
				setValueCodeAddCountry("");
			}
		} else {
			setShowWarning(true);
			setStringWarnCode(i18n.t("FORMS.labelOrCodeRequired"));
			setTimeout(() => {
				setShowWarning(false);
			}, 3000);
		}
	};

	/**
	 * Field WhiteList return if normal field or if have whitelist
	 * @returns Field
	 */

	const whiteListItem = () => {
		if (whitelist && !_lod.isEmpty(whitelist) && !isArray) {
			return (
				<MDBox mt={2}>
					<FormControl fullWidth onClick={() => copyValue(value)}>
						<InputLabel id="select-label">{label}</InputLabel>
						<Select
							labelId="select-label"
							id="select"
							label={label}
							value={value || ""}
							onChange={onChangeGate}
							name={child}
							disabled={!PI}
						>
							{whitelist.map((option, index) => (
								<MenuItem key={index} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</MDBox>
			);
		} else if (whitelistDynamic && !_lod.isEmpty(whitelistDynamic)) {
			return (
				<MDBox mt={2} onClick={() => copyValue(value)}>
					<Autocomplete
						id="auto"
						freeSolo
						name={child}
						key={key}
						disabled={!PI}
						options={optionAuto}
						onChange={onChangeCRM}
						getOptionLabel={getOptionLabel}
						inputValue={valueAuto || ""}
						renderInput={params => (
							<MDInput {...params} label={label} onChange={e => ValueChange(e.target.value)} />
						)}
					/>
				</MDBox>
			);
		} else {
			let list = whitelist || [];
			return (
				<MDBox mt={2} onClick={() => copyValue(value)}>
					{isArray ? (
						<Tooltip
							open={viewInvalid === child}
							title={i18n.t("FORMS.whitelistInvalidList")}
							placement="bottom"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={_lod.isArray(value) ? value : []}
								onChange={(event, newValue) => {
									if (_lod.isEmpty(list)) {
										onChangeGateTags(newValue, type, child);
									} else {
										const stringValuesWhite = newValue.filter(val => list.includes(val));
										if (stringValuesWhite.length === newValue.length) {
											onChangeGateTags(stringValuesWhite, type, child);
										} else {
											setViewInvalid(child);
											setTimeout(() => {
												setViewInvalid("");
											}, 3000);
										}
									}
								}}
								readOnly={!PI}
								multiple
								id="tags-filled"
								options={list}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip label={option} {...getTagProps({ index })} />;
									});
								}}
								renderInput={params => <MDInput {...params} label={label} />}
							/>
						</Tooltip>
					) : (
						<MDInput
							name={child}
							type={type}
							className="dialogInput"
							label={label}
							key={key}
							value={value || ""}
							disabled={!PI}
							onChange={onChangeGate}
						/>
					)}
				</MDBox>
			);
		}
	};

	// Special handle for defaultValue of dicitonary
	if (child === "defaultValue" && routeDict === "dictionary") {
		let defaultType = _lod.get(values, "type");
		if (!["code", "level", "labels", "color", "picture"].includes(defaultType)) {
			type = defaultType;
			onChangeGate = e => {
				onChange({
					event: e,
					defaultType: defaultType
				});
			};
		} else {
			type = "none";
		}
	}

	if (child === "whitelist" && routeDict === "dictionary") {
		let defaultType = _lod.get(values, "type");
		if (
			!defaultType ||
			["boolean", "code", "level", "labels", "color", "picture"].includes(defaultType)
		) {
			type = "none";
		} else {
			type = defaultType;
		}
	}

	if (child === "isDynamic" && routeDict === "dictionary") {
		let defaultType = _lod.get(values, "type");
		if (!defaultType || ["boolean", "code", "level", "color", "picture"].includes(defaultType)) {
			type = "none";
		}
	}

	switch (type) {
		case "boolean":
			return <BooleanInput value={value} PI={PI} label={label} onChange={onChangeGate} />;
		case "phone":
			if (whitelist) {
				return (
					<MDBox mt={2}>
						<FormControl fullWidth onClick={() => copyValue(value)}>
							<InputLabel id="select-label">{label}</InputLabel>
							<Select
								labelId="select-label"
								id="select"
								label={label}
								value={value || ""}
								onChange={onChangeGate}
								name={child}
								disabled={!PI}
							>
								{whitelist.map((option, index) => (
									<MenuItem key={index} value={option}>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</MDBox>
				);
			} else {
				return (
					<>
						<MDBox mt={2} onClick={() => copyValue(value)}>
							<MuiPhoneNumber
								defaultCountry="fr"
								onChange={onChangeGate}
								name={child}
								type="tel"
								className="dialogInput"
								label={label}
								key={key}
								value={value || null}
								disabled={!PI}
								variant="outlined"
							/>
						</MDBox>
						<p style={{ color: "red" }}>{customErrors}</p>
					</>
				);
			}
		case "date":
			const handleCopyDate = e => {
				if (!PI) {
					let dateParse = new Date(e);
					copyValue(dateParse.toLocaleDateString());
				}
			};

			return (
				<>
					<MDBox mt={2} onClick={() => handleCopyDate(value)}>
						<MDDatePicker
							name={child}
							type="date"
							className="dialogInput"
							label={label}
							key={key}
							value={value || null}
							disabled={!PI}
							onChange={onChangeGate}
							options={{
								time_24hr: true,
								dateFormat: "d M Y"
							}}
							input={{
								className: "fullWidthDatePicker date_input_light",
								placeholder: `${label} `,
								disabled: !PI,
								style: { width: "100%" }
							}}
						/>
					</MDBox>

					<p style={{ color: "red" }}>{customErrors}</p>
				</>
			);
		case "number":
			let list = whitelist || [];
			return (
				<MDBox>
					{isArray ? (
						<Tooltip
							open={viewInvalid === child}
							title={i18n.t("FORMS.whitelistInvalidList")}
							placement="bottom"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={_lod.isArray(value) ? value : []}
								onChange={(event, newValue) => {
									if (_lod.isEmpty(list)) {
										const numericValues = newValue.map(val => parseFloat(val));
										onChangeGateArray(numericValues, type, child);
									} else {
										const numericValues = newValue.map(val => parseFloat(val));
										const numericValuesWhite = numericValues.filter(val => list.includes(val));
										if (numericValuesWhite.length === newValue.length) {
											onChangeGateArray(numericValuesWhite, type, child);
										} else {
											setViewInvalid(child);
											setTimeout(() => {
												setViewInvalid("");
											}, 3000);
										}
									}
								}}
								readOnly={!PI}
								multiple
								id="tags-filled"
								options={list}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip label={option} {...getTagProps({ index })} />;
									});
								}}
								renderInput={params => <MDInput {...params} type="number" label={label} />}
							/>
						</Tooltip>
					) : _lod.isArray(whitelist) && !_lod.isEmpty(whitelist) ? (
						whiteListItem()
					) : (
						<>
							<MDBox mt={2} onClick={() => copyValue(value)}>
								<MDInput
									name={child}
									type="number"
									className="dialogInput"
									label={label}
									key={key}
									value={value || null}
									disabled={!PI}
									onChange={onChangeGate}
								/>
							</MDBox>
							<p style={{ color: "red" }}>{customErrors}</p>
						</>
					)}
				</MDBox>
			);
		case "code":
			if (whitelistDynamic) {
				return (
					<>
						{whiteListItem()}
						<p style={{ color: "red" }}>{customErrors}</p>
					</>
				);
			} else {
				return (
					<FormControl fullWidth onClick={() => copyValue(value)}>
						<InputLabel id="select-label">{label}</InputLabel>
						<Select
							labelId="select-label"
							id="select"
							label={label}
							value={value || ""}
							onChange={onChangeGate}
							name={child}
							disabled={!PI}
						>
							{codes
								.sort((a, b) => a.label.localeCompare(b.label))
								.map((code, index) => (
									<MenuItem key={index} value={code.value}>
										{code.label}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				);
			}
		case "email":
			let listEmail = whitelist || [];
			return (
				<MDBox>
					{isArray ? (
						<Tooltip
							open={child === viewInvalid}
							title={viewInvalidEmail}
							placement="right"
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Autocomplete
								value={_lod.isArray(value) ? value : []}
								onChange={(event, newValue) => {
									const isValid = newValue.every(val => val === "" || regexMail(val, regexEmail));

									if (isValid) {
										if (_lod.isEmpty(listEmail)) {
											onChangeGateArray(newValue, type, child);
										} else {
											const stringValuesWhite = newValue.filter(val => listEmail.includes(val));
											if (stringValuesWhite.length === newValue.length) {
												onChangeGateArray(stringValuesWhite, type, child);
											} else {
												setViewInvalid(child);
												setViewInvalidEmail(i18n.t("FORMS.whitelistInvalidList"));

												setTimeout(() => {
													setViewInvalid("");
													setViewInvalidEmail("");
												}, 3000);
											}
										}
									} else {
										setViewInvalid(child);
										setViewInvalidEmail(i18n.t("FORMS.emailInvalidList"));

										setTimeout(() => {
											setViewInvalidEmail("");

											setViewInvalid("");
										}, 3000);
									}
								}}
								readOnly={!PI}
								multiple
								id="tags-filled"
								options={listEmail}
								freeSolo
								renderTags={(tags, getTagProps) => {
									return tags.map((option, index) => {
										return <Chip label={option} {...getTagProps({ index })} />;
									});
								}}
								renderInput={params => <MDInput {...params} label={label} />}
							/>
						</Tooltip>
					) : _lod.isArray(whitelist) && !_lod.isEmpty(whitelist) ? (
						whiteListItem()
					) : (
						<MDBox mt={2} onClick={() => copyValue(value)}>
							<MDInput
								name={child}
								type={type}
								className="dialogInput"
								label={label}
								key={key}
								value={value || ""}
								disabled={!PI}
								onChange={onChange}
							/>
						</MDBox>
					)}
				</MDBox>
			);
		case "codeImage":
			return (
				<MDBox mt={2} onClick={() => copyValue(value)}>
					<FormControl style={{ width: "12%" }}>
						<InputLabel id="select-label">{label}</InputLabel>
						<Select
							labelId="select-label"
							id="select"
							label={label}
							value={value || ""}
							onChange={onChange}
							name={child}
							disabled={!PI}
						>
							{codes.map((code, index) => (
								<MenuItem key={index} value={code.value}>
									<img key={index} src={code.url} alt="Filter_flag" width="20" height="20" />
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</MDBox>
			);
		case "mdp":
			return (
				<>
					<MDBox mt={2} onClick={() => copyValue(value)}>
						{isEmpty && (
							<MDBox className="password-input-wrapper">
								<MDInput
									name={child}
									type={showPassword ? "text" : "password"}
									className="dialogInput"
									style={{ width: "94%" }}
									label={label}
									key={key}
									value={value || null}
									disabled={!PI}
									onChange={onChange}
								/>
								<Tooltip
									title={showPassword ? i18n.t("FORMS.hidePassword") : i18n.t("FORMS.showPassword")}
									placement="right"
								>
									<IconButton
										className="show-password-button"
										onClick={handleTogglePasswordVisibility}
									>
										{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
									</IconButton>
								</Tooltip>
							</MDBox>
						)}
					</MDBox>
					<Card style={{ width: "94%" }}>{customErrors}</Card>
				</>
			);
		case "picture":
			let imagePreview = true;

			let imageSource;
			if (value === "" || _lod.isNil(value)) {
				imagePreview = false;
				imageSource = defaultImage;
			} else {
				imageSource = value;
			}

			return (
				<MDBox mt={2} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<Typography>{label}</Typography>

					{imagePreview ? (
						<MDBox>
							{PI && (
								<IconButton
									className="picture-delete"
									aria-label="delete"
									size="small"
									onClick={onChangeGate}
								>
									<ClearIcon />
								</IconButton>
							)}
							<Avatar
								style={{ width: 100, height: 100, objectFit: "cover" }}
								alt="Profil"
								src={imageSource}
							/>
						</MDBox>
					) : (
						<MDBox sx={{ width: 100 }} mt={2}>
							<label htmlFor="image-input" style={{}}>
								<Avatar
									style={{ width: 100, height: 100, cursor: "pointer", objectFit: "cover" }}
									alt="default"
									src={imageSource}
								/>

								<input
									id="image-input"
									disabled={!PI}
									style={{ display: "none" }}
									type="file"
									accept="image/*"
									onChange={handleImageChange}
								/>
							</label>
						</MDBox>
					)}
					<p style={{ color: "red" }}>{customErrors}</p>
				</MDBox>
			);
		case "tagList":
			let listTag = [];
			return (
				<Autocomplete
					value={_lod.isArray(value) ? value : []}
					onChange={(event, newValue) => {
						onChangeGateTags(newValue, type, child);
					}}
					readOnly={!PI}
					multiple
					id="tags-filled"
					options={listTag}
					freeSolo
					renderTags={(tags, getTagProps) => {
						return tags.map((option, index) => {
							return <Chip label={option} {...getTagProps({ index })} />;
						});
					}}
					renderInput={params => <MDInput {...params} label={label} />}
				/>
			);
		case "check":
			return (
				<MDBox>
					{routeDict === "dictionary" ? (
						<MDBox>
							{values?.type && !["tagList", "boolean", "code", "color"].includes(values?.type) ? (
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox
												checked={value}
												onChange={e =>
													onChange(
														{ target: { name: child, value: e.target.checked } },
														{ value: e.target.checked, type },
														child
													)
												}
											/>
										}
										label={label}
									/>
								</FormGroup>
							) : (
								<MDBox></MDBox>
							)}
						</MDBox>
					) : (
						<FormGroup>
							<FormControlLabel control={<Checkbox checked={value} />} label={label} />
						</FormGroup>
					)}
				</MDBox>
			);
		case "none":
			return <MDBox></MDBox>;
		case "labels":
			return (
				<Accordion className="customAccordion" key={key}>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<span style={{ fontWeight: "bolder" }}>{label}</span>
						</Typography>
					</AccordionSummary>
					<AccordionDetails style={{ display: "flex", width: "100%", flexDirection: "column" }}>
						<MDBox>
							<MDTypography variant="h6">{i18n.t("FORMS.addCode")}</MDTypography>

							<MDBox style={{ display: "flex", width: "100%", alignItems: "center" }}>
								<Autocomplete
									id="auto"
									style={{ width: "20%", marginRight: "1%" }}
									freeSolo
									name={child}
									key={key}
									disabled={!PI}
									options={optionAuto}
									onChange={onChangeCountry}
									getOptionLabel={getOptionLabel}
									inputValue={addCodeCountry}
									renderInput={params => (
										<MDInput
											{...params}
											label={label}
											onChange={e => ValueChangeCountry(e.target.value)}
										/>
									)}
								/>
								<MDInput
									name={child}
									label={label}
									key={key}
									value={addLabelCountry}
									disabled={!PI}
									onChange={e => setLabelAddCountry(e.target.value)}
								/>
								<Tooltip
									open={showWarning}
									title={stringWarnCode}
									placement="right"
									disableFocusListener
									disableHoverListener
									disableTouchListener
								>
									<IconButton disabled={!PI} size="small" onClick={() => addLabel()}>
										<Add />
									</IconButton>
								</Tooltip>
							</MDBox>
							<hr style={{ borderLeft: "1px solid #ccc", height: "100%", margin: "10px 0px" }} />
							<Typography>
								<MDTypography variant="h6">{i18n.t("FORMS.listCodes")}</MDTypography>
							</Typography>

							{!_lod.isEmpty(value) ? (
								<>
									{Object.keys(value).map((childItem, key) => {
										return (
											<MDBox style={{ display: "flex", alignItems: "center" }}>
												<Autocomplete
													id="auto"
													style={{ width: "20%", marginRight: "1%" }}
													freeSolo
													name={childItem}
													key={key}
													disabled
													options={optionAuto}
													onChange={onChangeCRM}
													getOptionLabel={getOptionLabel}
													inputValue={childItem}
													renderInput={params => <MDInput {...params} label={label} />}
												/>
												<MDInput
													name={child + "." + childItem}
													label={label}
													key={key}
													value={value[childItem]}
													disabled={!PI}
													onChange={e => onChangeGateCountry(e.target.value, childItem)}
												/>
												<Tooltip
													open={showLabelWarning && childItem === indexWarnCode}
													title={i18n.t("FORMS.labelRequired")}
													placement="right"
													disableFocusListener
													disableHoverListener
													disableTouchListener
												>
													<IconButton
														disabled={!PI}
														size="small"
														onClick={() => deleteLabel(childItem)}
													>
														<Delete />
													</IconButton>
												</Tooltip>
											</MDBox>
										);
									})}
								</>
							) : (
								<Typography>
									<MDTypography variant="h6">{i18n.t("FORMS.emptyCodes")}</MDTypography>
								</Typography>
							)}
						</MDBox>
					</AccordionDetails>
				</Accordion>
			);
		case "color":
			return (
				<>
					<MDBox mt={1} className="colorContainer">
						<MDBox className="colorPreset">
							<TwitterPicker
								width="100%"
								triangle="hide"
								color={color}
								onChange={handleColorSelection}
							/>
						</MDBox>
						<MDBox
							className="buttonColorPicker"
							style={{
								backgroundColor: color.hex
							}}
							onClick={displayPicker}
						>
							<Icon fontSize="medium" className="buttonColorPickerIcon">
								colorize
							</Icon>
						</MDBox>
					</MDBox>
					<Popper
						id={pickerRef ? "simple-popper" : ""}
						open={Boolean(pickerRef) && PI}
						anchorEl={pickerRef}
						placement="right"
						style={{
							zIndex: 10000
						}}
					>
						{() => (
							<ClickAwayListener
								onClickAway={e => {
									setPickerRef(null);
								}}
							>
								<Paper>
									<ChromePicker disableAlpha color={color} onChange={handleColorSelection} />
								</Paper>
							</ClickAwayListener>
						)}
					</Popper>
				</>
			);
		default:
			return (
				<>
					{whiteListItem()}
					<p style={{ color: "red" }}>{customErrors}</p>
				</>
			);
	}
};
