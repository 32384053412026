/* eslint-disable import/prefer-default-export */

import { FmdGood, NearMe } from "@mui/icons-material";
import { Icon } from "@mui/material";
import { Tooltip } from "chart.js";
import MDBox from "components/Basics/MDBox";
import lod_ from "lodash";

const ColorItemLabel = ({ color }) => {
	return (
		<div
			style={{
				height: "3vh",
				width: "3vh",
				borderRadius: "7px",
				backgroundColor: color
			}}
		></div>
	);
};

const createObjectTable = (obj, cell, dictionary, form) => {
	let collection = form?.collectionDefault;
	let attribute = cell?.column?.id;

	if (!collection || !attribute) return null;

	let midPath = collection + "." + attribute;

	let sample = obj[0];
	let sampleKeys = Object.keys(sample);

	return (
		<table>
			<thead>
				<tr>
					{sampleKeys.map((key, index) => {
						let realPath = midPath + "." + key;
						realPath = realPath.split(".")?.join(".items.");
						let fromDictionary = lod_.get(dictionary, realPath);
						if (fromDictionary) {
							return <th key={index}>{fromDictionary.label.fr}</th>;
						} else {
							return null;
						}
					})}
				</tr>
			</thead>
			<tbody>
				{obj.map((row, index) => {
					return (
						<tr key={index}>
							{sampleKeys.map((key, index) => {
								return <td key={index}>{row[key]}</td>;
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

const createArrayTable = arr => {
	return <div>{arr.join(", ")}</div>;
};

/**
 * ##############################
 * Display special fields in table
 * e.g : colors, icons, images, etc
 * ##############################
 */
export function handleSpecialCell(cell, rowKey, dictionary, form) {
	let filtersLabel = [];
	switch (cell.column.id) {
		case "filters":
			cell.value.map((filter, index) => {
				let realPath = filter.dictionaryPath?.split(".")?.join(".items.");
				let fromDictionary = lod_.get(dictionary, realPath);
				if (fromDictionary && fromDictionary.analytics && fromDictionary.active) {
					filtersLabel.push(fromDictionary.label.fr);
				}
			});
			return <div>{filtersLabel.join(" / ")}</div>;
		case "labels":
			return (
				<MDBox>
					{!lod_.isEmpty(cell.value) ? (
						<>
							{Object.keys(cell.value).map((childItem, key) => {
								return (
									<div style={{ display: "flex" }}>
										<span style={{ fontWeight: "bolder" }}>{childItem} </span>
										<span>{" : " + cell.value[childItem]}</span>
									</div>
								);
							})}
						</>
					) : (
						<MDBox></MDBox>
					)}
				</MDBox>
			);

		case "color":
			return <ColorItemLabel color={cell.value} />;
		case "display.pages":
			return <div>{cell.value.join(", ")}</div>;
		case "images":
			if (!cell.value) return null;
			return <div>voir (bientot dispo)</div>;
		case "createdDate":
			return <span>{new Date(cell.value).toLocaleString("FR-fr")}</span>;
		case "active":
		case "analysed":
			if (!cell.value) {
				return (
					<Icon color="error" fontSize="medium">
						close
					</Icon>
				);
			} else {
				return (
					<Icon color="success" fontSize="medium">
						check
					</Icon>
				);
			}
		case "block":
			if (!cell.value) {
				return (
					<Icon color="success" fontSize="medium">
						check
					</Icon>
				);
			} else {
				return (
					<Icon color=" error" fontSize="medium">
						close
					</Icon>
				);
			}
		case "shoppingCenterLoc":
			if (cell.value === "in")
				return (
					<Tooltip placement="top" title="Dans le centre">
						<FmdGood />
					</Tooltip>
				);
			else
				return (
					<Tooltip placement="top" title="À proximité">
						<NearMe />
					</Tooltip>
				);
		default:
			if (lod_.isArray(cell.value)) {
				let sample = cell.value[0];

				if (lod_.isObject(sample)) {
					return createObjectTable(cell.value, cell, dictionary, form);
				} else {
					return createArrayTable(cell.value);
				}
			} else {
				return cell.render("Cell");
			}
	}
}
