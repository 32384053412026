import { setPermissions } from "redux-react/reducers/permissionsReducer";
import { updateMenu, selectProfile } from "redux-react/reducers/profileReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	selectProfile: (payload, assistants) => {
		return dispatch => {
			dispatch(selectProfile({ profile: payload.profile, assistants }));
			dispatch(setPermissions(payload.permissions));
		};
	},
	updateMenu: (assistantID, success) => {
		const onSuccess = res => {
			if (success) success(res.menu);
			return dispatch => {
				dispatch(updateMenu({ menu: res.menu }));
			};
		};
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/menu`,
					onSuccess,
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error?.message ?? "none"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;
