/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import { api } from "./api";

const actions = {
	updateAttributeDisplayPaginedList: (listCode, attributesList, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "PUT",
					url: `/api/v1/updateAttributeDisplayPaginedList/${listCode}`,
					data: { attributesList },
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	getPageFilters: (code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/filters/${code}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	},
	getChartByCode: (code, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/chart/${code}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	refreshPaginedList: (list, entriesParPage, page, search, sort, onSuccess) => {
		let data = {
			entriesParPage,
			page,
			search,
			request: list.request,
			filters: list.filters,
			sort
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/paginedList/pagination`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getPaginedList: (route, onSuccess) => {
		return dispatch => {
			dispatch(
				api({
					method: "GET",
					url: `/api/v1/paginedList/${route.route.replace("/", "")}`,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getCharts: (route, onSuccess) => {
		let data = {
			filter: route.filter
		};
		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/charts/${route.route.replace("/", "")}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	buildRawCharts: (charts, filters, mandatoryFilters, onSuccess) => {
		let data = {
			charts,
			filters,
			mandatoryFilters: mandatoryFilters ?? []
		};

		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: "/api/v1/charts",
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(setError(err.response?.data?.error ?? "none"));
					}
				})
			);
		};
	},
	getPageCharts: (route, filters, mandatoryFilters, onSuccess) => {
		let data = {
			filters,
			mandatoryFilters: mandatoryFilters ?? []
		};

		return dispatch => {
			dispatch(
				api({
					method: "POST",
					url: `/api/v1/pageCharts/${route.replace("/", "")}`,
					data,
					onSuccess: res => {
						onSuccess(res);
					},
					onFailure: err => {
						dispatch(
							display({
								type: "error",
								message: err.response?.data?.error ?? "none"
							})
						);
					}
				})
			);
		};
	}
};

export default actions;
