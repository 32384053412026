/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import axios from "axios";
import store from "redux-react/store";

export async function getSignedUrlS3(file, target) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;

	let uploadSignedUrlResponse = await axios(endPoint + "/api/v1/s3/v3/getSignedUrl", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			size: file.size,
			fileName: file.name,
			contentType: file.type,
			target
		}
	});

	return uploadSignedUrlResponse.data.payload.result;
}

export async function uploadToSignedUrl(signedUrl, file) {
	const response = await axios.put(signedUrl, file, {
		headers: {
			"Content-Type": file.type
		}
	});

	return {};
}

export async function removeToSignedUrl(urlFile, target) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;

	let uploadSignedUrlResponse = await axios(endPoint + "/api/v1/s3/v3/deleteFileUrl", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			urlFile,
			target
		}
	});
	return {};
}
