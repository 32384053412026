/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import PrivateRoute from "PrivateRoute";
import { Provider } from "react-redux";
import { ProvideAuthFaibrik } from "context/ProvideAuthFaibrik";
import { createRoot } from "react-dom/client";
import store from "redux-react/store";
import { Auth0Provider } from "./react-auth0-wrapper";
import config from "./auth_config.json";

const onRedirectCallback = appState => {
	window.history.replaceState(
		{},
		document.title,
		appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
	);
};

const root = createRoot(document.getElementById("root"));

root.render(
	<Auth0Provider
		domain={config.domain}
		client_id={config.clientId}
		redirect_uri={config.redirect_uri}
		audience={config.audience}
		onRedirectCallback={onRedirectCallback}
	>
		<Provider store={store}>
			<ProvideAuthFaibrik>
				<Suspense fallback={<div className="loading"></div>}>
					<BrowserRouter>
						<MaterialUIControllerProvider>
							<PrivateRoute path="/" component={App} />
						</MaterialUIControllerProvider>
					</BrowserRouter>
				</Suspense>
			</ProvideAuthFaibrik>
		</Provider>
	</Auth0Provider>
);
