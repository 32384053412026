/* eslint-disable no-underscore-dangle */
/* eslint-disable no-continue */
/* eslint-disable no-empty */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-destructuring */
/**
 * Main application charts
 */
import MDBox from "components/Basics/MDBox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormActions from "redux-react/actions/formAction";
import {
	Divider,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tabs,
	Tooltip
} from "@mui/material";
import MDTypography from "components/Basics/MDTypography";
import { socket } from "redux-react/middleware/ws";
import { display } from "redux-react/reducers/snackBarReducer";

/**
 */
export default function ChangesTab({ changePannelContent }) {
	const dispatch = useDispatch();

	/*  */
	const [clients, setClients] = useState([]);
	const [assistants, setAssistants] = useState([]);
	const [account, setAccount] = useState("all");
	const [selectedAssistant, setSelectedAssistant] = useState(null);

	const [collections, setCollections] = useState([]);

	const [changes, setChanges] = useState(null);
	const [tab, setTab] = useState(0);

	useEffect(() => {
		if (!selectedAssistant || !collections[tab]) return;
		socket.emit("get_deployment_changes", {
			assistantID: selectedAssistant,
			collection: collections[tab]
		});
	}, [selectedAssistant, tab]);

	const changesResult = data => {
		setChanges(data);
	};

	const getClusterCollectionsResult = data => {
		setCollections(data.result.collections);
	};

	const insertResult = data => {
		if (data?.result?.success) {
			dispatch(
				display({
					message: "Document inséré avec succès",
					type: "success"
				})
			);
			socket.emit("get_deployment_changes", {
				assistantID: selectedAssistant,
				collection: collections[tab]
			});
		} else {
			dispatch(
				display({
					message: "Erreur lors de l'insertion du document",
					type: "error"
				})
			);
		}
	};

	useEffect(() => {
		socket.on("get_deployment_changes_result", changesResult);
		socket.on("get_cluster_collections_result", getClusterCollectionsResult);
		socket.on("add_item_from_catalog_to_client_result", insertResult);

		socket.emit("get_cluster_collections", {});

		dispatch(
			FormActions.getItemsFromCollection(
				"accountConfig",
				{
					catalog: "legacy"
				},
				({ items }) => {
					setClients(items);
				}
			)
		);

		return () => {
			socket.off("get_deployment_changes_result", changesResult);
			socket.off("get_cluster_collections_result", getClusterCollectionsResult);
			socket.off("add_item_from_catalog_to_client_result", insertResult);
		};
	}, []);

	return (
		<MDBox mt={2} bgColor="white" p={1} borderRadius="md" display="flex" flexDirection="column">
			<MDBox display="flex" flexDirection="row" alignItems="center" sx={{ mt: 1 }}>
				<FormControl sx={{ flex: 1 }}>
					<InputLabel id="select-acconut">Account</InputLabel>
					<Select
						labelId="select-account"
						id="select"
						name="target"
						label="Account"
						value={account}
						onChange={e => setAccount(e.target.value)}
					>
						<MenuItem
							value="all"
							onClick={() => {
								setAssistants([]);
							}}
						>
							Choisir
						</MenuItem>
						{clients.map((client, index) => (
							<MenuItem
								key={index}
								value={client.accountID}
								onClick={() => {
									setAssistants(client.assistants);
								}}
							>
								{client.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl sx={{ flex: 3, ml: 1 }}>
					<InputLabel id="select-acconut">AssistantID</InputLabel>
					<Select
						disabled={account === "all"}
						labelId="select-assistant"
						id="select"
						name="target"
						label="AssistantID"
						value={selectedAssistant}
						onChange={e => setSelectedAssistant(e.target.value)}
					>
						{assistants.map((assistant, index) => {
							return (
								<MenuItem
									key={index}
									value={assistant.assistantID}
									onClick={() => {
										setSelectedAssistant(assistant.assistants);
									}}
								>
									{assistant.assistantID}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</MDBox>

			<Tabs
				sx={{ mt: 1 }}
				orientation="horizontal"
				value={tab}
				onChange={(e, v) => {
					setTab(v);
				}}
			>
				{collections.map((collection, index) => {
					return (
						<Tab
							key={index}
							sx={{
								fontSize: "0.75rem"
							}}
							label={collection}
						/>
					);
				})}
			</Tabs>

			<Divider />

			<MDBox>
				{changes && (
					<MDBox>
						{changes.success && (
							<MDBox>
								<MDBox>
									<MDBox display="flex" alignItems="center" flexDirection="row">
										<MDTypography variant="h5" sx={{ mt: 3 }}>
											<Icon color="warning">warning</Icon>&nbsp;
											{`Documents manquants (${changes.result.missingTargetCount} / ${changes.result.sourceCount})`}
										</MDTypography>
									</MDBox>

									<Table>
										<TableHead
											style={{
												display: "contents"
											}}
										>
											<TableRow>
												<TableCell style={{ width: "70%" }}>code / _id</TableCell>
												<TableCell align="right" style={{ width: "30%" }}>
													Actions
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{changes?.result?.missing.map((missing, index) => {
												return (
													<MDBox component="tr">
														<TableCell>
															<MDTypography key={index} variant="body2" fontSize="small">
																{missing.code ?? "Pas de code"}
															</MDTypography>
														</TableCell>
														<TableCell align="right">
															<Tooltip placement="top" title="Ajouter dans la collection client">
																<IconButton
																	onClick={() => {
																		socket.emit("add_item_from_catalog_to_client", {
																			assistantID: selectedAssistant,
																			collection: collections[tab],
																			code: missing.code
																		});
																	}}
																>
																	<Icon>add</Icon>
																</IconButton>
															</Tooltip>
															<Tooltip placement="top" title="Voir le document">
																<IconButton
																	onClick={() => {
																		changePannelContent({
																			code: missing.code,
																			client: "catalog",
																			collection: collections[tab]
																		});
																	}}
																>
																	<Icon>visibility</Icon>
																</IconButton>
															</Tooltip>
														</TableCell>
													</MDBox>
												);
											})}
										</TableBody>
									</Table>
								</MDBox>

								<Divider />
								<MDBox>
									<MDTypography variant="h5" sx={{ mt: 3 }}>
										<Icon color="info">extension</Icon>&nbsp;
										{`Documents en trop (${changes.result.missingSourceCount} / ${changes.result.targetCount})`}
									</MDTypography>

									<Table>
										<TableHead
											style={{
												display: "contents"
											}}
										>
											<TableRow>
												<TableCell style={{ width: "70%" }}>code / _id</TableCell>
												<TableCell align="right" style={{ width: "30%" }}>
													Actions
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{changes?.result?.surplus.map((extra, index) => {
												return (
													<MDBox component="tr">
														<TableCell>
															<MDTypography key={index} variant="body2" fontSize="small">
																{extra.code ?? "Pas de code"}
															</MDTypography>
														</TableCell>
														<TableCell align="right">
															<Tooltip placement="top" title="Importer dans le catalogue">
																<IconButton onClick={() => {}}>
																	<Icon>cloud_download</Icon>
																</IconButton>
															</Tooltip>
															<Tooltip placement="top" title="Voir le document">
																<IconButton
																	onClick={() => {
																		changePannelContent({
																			code: extra.code,
																			client: selectedAssistant,
																			collection: collections[tab]
																		});
																	}}
																>
																	<Icon>visibility</Icon>
																</IconButton>
															</Tooltip>
														</TableCell>
													</MDBox>
												);
											})}
										</TableBody>
									</Table>
								</MDBox>

								<Divider />

								<MDBox>
									<MDTypography variant="h5" sx={{ mt: 3 }}>
										<Icon color="success">compare_arrows</Icon>&nbsp;
										{`Documents différents (${changes.result.diffs.length})`}
									</MDTypography>
									{changes?.result?.diffs.map((diff, index) => {
										return (
											<MDBox>
												<Divider />
												<MDTypography variant="h6">{diff.code ?? diff._id}</MDTypography>
												{Object.keys(diff.diff).map((key, index) => {
													let obj = diff.diff[key];
													return (
														<Table>
															<TableHead
																style={{
																	display: "contents"
																}}
															>
																<TableRow>
																	<TableCell
																		style={{ width: "50%" }}
																	>{`Catalog (${key})`}</TableCell>
																	<TableCell
																		style={{ width: "50%" }}
																	>{`Assistant (${key})`}</TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																<MDBox component="tr">
																	<TableCell>{JSON.stringify(obj?.catalog)}</TableCell>
																	<TableCell> {JSON.stringify(obj?.assistant)}</TableCell>
																</MDBox>
															</TableBody>
														</Table>
													);
												})}
											</MDBox>
										);
									})}
								</MDBox>
							</MDBox>
						)}
					</MDBox>
				)}
			</MDBox>
		</MDBox>
	);
}
