/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * #######################################################@
 *
 * Error page
 *
 * Mandatory page to display errors on screen
 *
 * #######################################################@
 */
import { useAuth0 } from "react-auth0-wrapper";

const ErrorPage = ({ code, message }) => {
	const { logout } = useAuth0();

	return (
		<div className="errorContainer">
			<div>
				<p className="errorCode">{`Erreur : ${code} `}</p>
				<p className="errorDisplay">{`"${message}"`}</p>

				<div
					className="errorButton"
					onClick={() => {
						logout({ returnTo: window.location.origin });
					}}
				>
					Réessayer
				</div>
			</div>
		</div>
	);
};

export default ErrorPage;
