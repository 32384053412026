/* eslint-disable no-underscore-dangle */

/**
 * Main application management
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "redux-react/middleware/ws";
import ChartsActions from "redux-react/actions/chartsActions";
import lod_ from "lodash";
import {
	Badge,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Icon,
	IconButton,
	Menu,
	MenuItem,
	Tooltip
} from "@mui/material";
import axios from "axios";
import i18n from "i18n";
import { useMaterialUIController } from "context";
import ChartsLoader from "components/Custom/ChartsLoader";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import { display } from "redux-react/reducers/snackBarReducer";
import MDTypography from "components/Basics/MDTypography";
import OperationActions from "redux-react/actions/operationActions";
import FormActions from "redux-react/actions/formAction";
import { RestartAlt, StopCircle, PlayCircleFilledWhite } from "@mui/icons-material";
import FormDictionaryDialog from "components/Custom/FormDictionary";
import { JSONTree } from "react-json-tree";

const ButtonNewLabel = ({ handleClick, namePage }) => {
	return (
		<MDButton
			style={{ height: "100%", marginRight: "0.75rem" }}
			variant="contained"
			color="info"
			onClick={handleClick}
			disabled
		>
			<Icon>add</Icon>&nbsp;{i18n.t("FORMS.LABELS.add") + namePage}
		</MDButton>
	);
};

/**
 *
 * @param {Object} route
 *  - key : actual page
 *  - type : type of page
 *  - icon : icon to display in navbar
 *  - route : actual route
 * @returns
 */
export default function OperationPage({ route }) {
	const dispatch = useDispatch();

	const { filters } = useSelector(state => state);
	const [controller] = useMaterialUIController();
	const { darkMode } = controller;
	// Loader while charts are loading
	const [chartsLoading, setChartsLoading] = useState(false);
	// Raw charts from collection "analytics" in DB
	const [rawCharts, setRawCharts] = useState({});
	// Raw charts builded from the back
	const [chartsData, setChartsData] = useState({});
	// Default assistant filters
	const [pageFilters, setPageFilters] = useState([]);
	const [openPannel, setOpenPannel] = useState(false);
	const [selectedComponent, setSelectedComponent] = useState({});
	const rightPannelContainer = useRef(null);
	const [reloadTable, setReloadTable] = useState(false);
	const [selectedAction, setSelectedAction] = useState("");
	const [confirmAction, setConfirmAction] = useState(false);
	const [attributeMyCode, setAttributeMyCode] = useState([]);
	const [messagesList, setMessagesList] = useState([]);
	const [useLegacy, setUseLegacy] = useState(false);
	const [dbName, setDbName] = useState(null);

	const [addNewLabel, setAddNewLabel] = useState(false);
	const [valuesForm, setValuesForm] = useState({});
	const [formBuild, setFormBuild] = useState({});
	const [contextDictionary, setContextDictionary] = useState({});
	const [PI, setPI] = useState(false);
	const [empty, setEmpty] = useState(false);
	const [listAPIs, setlistAPIs] = useState([]);

	const [selectedTarget, setSelectedTarget] = useState("");
	const [routeDict, setRouteDict] = useState("");
	const [selectedId, setSelectedId] = useState(null);
	const [selectedJSON, setSelectedJSON] = useState({});
	const [componentStatus, setComponentStatus] = useState(<div></div>);
	const [componentStatusTime, setComponentStatusTime] = useState(null);
	/**
	 * Get charts data to be displayed in front
	 */
	function getChartsData() {
		return chartsData[route.route] ?? [];
	}
	/**
	 * FIRST STEP : Get raw charts from DB
	 */
	function getRawChartsFromDatabase() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts({ ...rawCharts, [route.route]: res.charts });
		};
		dispatch(ChartsActions.getCharts(route, onSuccess));
	}
	/**
	 * SECOND STEP : Build charts with the raw charts on the back
	 */
	function buildRawCharts() {
		// Success handler : set charts data
		const onSuccess = res => {
			setChartsData({ ...chartsData, [route.route]: res.charts });
			setChartsLoading(false);
			const chart = rawCharts[route.route][0];
			let operation;
			if (lod_.isNil(chart.operation) || chart.operation === false) {
				operation = false;
			} else {
				operation = true;
			}
			setUseLegacy(operation);
			if (chart.request.dbName) {
				setDbName(chart.request.dbName);
			}
		};
		// Build charts
		if (!rawCharts[route.route]) return;
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);
		let actualFilters = getLocalStorageBackValues(route.route, filters);
		dispatch(
			ChartsActions.buildRawCharts(
				rawCharts[route.route],
				actualFilters,
				mandatoryFilters,
				onSuccess
			)
		);
	}

	function socketPing(res) {
		let componentStatusCode = selectedComponent ? selectedComponent.myCode : "";
		if (componentStatusCode === res.code) {
			let pingStatus = res.aliveTimeout === "check pingDelta" ? "red" : "green";
			if (res.aliveTimeout === null) {
				pingStatus = "blue";
			}

			setComponentStatus(
				<div
					style={{
						background: pingStatus + "",
						borderRadius: 50 + "%",
						height: 20 + "px",
						width: 20 + "px"
					}}
				></div>
			);
			setComponentStatusTime(res.datetime);
		}
	}

	function addStatusPoint(data) {
		let lastPing = data.ping.pop();
		let lastMissed = data.missed && data.missed.length > 0 ? data.missed.pop() : 0;
		let lastReboot = data.reboot && data.reboot.length > 0 ? data.reboot.pop() : 0;
		let lastStatus = Math.max(lastMissed.ts, lastPing.ts, lastReboot.ts);
		let pingStatus = lastStatus === lastMissed ? "red" : "green";
		let lastPingTime = lastStatus === lastMissed ? lastMissed.datetime : lastPing.datetime;
		if (lastStatus === lastReboot) {
			pingStatus = "blue";
			lastPingTime = lastReboot.datetime;
		}
		setComponentStatus(
			<div
				style={{
					background: pingStatus + "",
					borderRadius: 50 + "%",
					height: 20 + "px",
					width: 20 + "px"
				}}
			></div>
		);
		setComponentStatusTime(lastPingTime);
	}

	useEffect(() => {
		socket.on("sendStatus", socketPing);
		return () => {
			socket.off("sendStatus", socketPing);
		};
	}, []);
	/**
	 * FIRST STEP : Get raw charts :
	 * - On mount / when page change
	 * - On selected assistant change
	 */
	useEffect(() => {
		// if (!chartsData[page]) {
		setChartsLoading(true);
		getRawChartsFromDatabase();
		// }
	}, [route]);
	/**
	 * SECOND STEP : Build charts :
	 * - On filters change
	 * - On rawCharts change
	 */
	useEffect(() => {
		buildRawCharts();
	}, [filters, rawCharts]);
	/**
	 * Load filters on mount
	 */

	const actionViewHandle = (item, collection) => {
		setOpenPannel(true);
		setSelectedComponent(item);
		// if scroll in page is above rightPannelContainer ref, scroll to it
		setTimeout(() => {
			let scrollPosition = document.documentElement.scrollTop;
			let pannelTopPosiiton = rightPannelContainer.current.offsetTop;

			if (scrollPosition < pannelTopPosiiton) {
				rightPannelContainer.current.scrollIntoView({
					behavior: "smooth",
					block: "start"
				});
			}
		}, 200);

		const onSuccess = res => {
			setSelectedJSON(res.message);
			if (collection === "platformConfig") {
				addStatusPoint(res.status);
			}
		};
		dispatch(OperationActions.getMessageByID({ message: item, collection }, onSuccess));
	};

	const actionCheckHandle = (item, event) => {
		let copyAttributeMyCode = lod_.cloneDeep(attributeMyCode);
		if (event.target.checked) {
			copyAttributeMyCode.push({ _id: item._id, myCode: item.myCode });
		} else {
			copyAttributeMyCode = lod_.without(
				copyAttributeMyCode,
				lod_.find(copyAttributeMyCode, obj =>
					lod_.isEqual(obj, { _id: item._id, myCode: item.myCode })
				)
			);
		}
		setAttributeMyCode(copyAttributeMyCode);
	};

	const closePannel = () => {
		setOpenPannel(false);
		setSelectedComponent({});
	};

	const closeActionHandle = () => {
		setConfirmAction(false);
		setSelectedAction("");
	};

	const openActionHandle = action => {
		setConfirmAction(true);
		setSelectedAction(action);
	};

	const actionComponent = (item, action) => {
		const onSuccess = res => {
			let messageSuccess = "";
			if (action === "restart") {
				messageSuccess = i18n.t("OPERATION.ACTION.restart");
			}
			if (action === "stop") {
				messageSuccess = i18n.t("OPERATION.ACTION.stop");
			}
			if (action === "clearCache") {
				messageSuccess = i18n.t("OPERATION.ACTION.clearCache");
			}
			dispatch(
				display({
					message: messageSuccess,
					type: "success"
				})
			);
		};
		if (action === "restart") {
			dispatch(OperationActions.restartComponent(item, onSuccess));
		}
		if (action === "stop") {
			dispatch(OperationActions.stopComponent(item, onSuccess));
		}
		if (action === "clearCache") {
			dispatch(OperationActions.cleaCacheComponent(item, onSuccess));
		}
	};

	const getActionLabel = action => {
		switch (action) {
			case "restart":
				return (
					<MDBox>
						{i18n.t("OPERATION.LABELS.confirmRestart")} {selectedComponent.mySelf}
					</MDBox>
				);
			case "stop":
				return (
					<MDBox>
						{i18n.t("OPERATION.LABELS.confirmStop")} {selectedComponent.mySelf}
					</MDBox>
				);
			case "clearCache":
				return (
					<MDBox>
						{i18n.t("OPERATION.LABELS.confirmClear")} {selectedComponent.mySelf}
					</MDBox>
				);

			default:
				return <MDBox>STOP</MDBox>;
		}
	};

	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenuClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const actionEditHandle = (items, target, useLegacy) => {
		const onSuccess = res => {
			setAddNewLabel(true);
			setEmpty(false);
			setValuesForm(res.valueDictionary);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(true);
			setlistAPIs(res.APIs);

			setSelectedId(items._id);
			setSelectedTarget(target);
			setRouteDict(route.form.routeDictionary);
		};
		dispatch(
			FormActions.getItemByID(
				items._id,
				target,
				route.form.routeDictionary,
				onSuccess,
				useLegacy,
				dbName,
				true,
				route.form.catalog
			)
		);
	};

	const handleOptionClick = action => {
		let uniqueMyCode = lod_.uniq(lod_.map(attributeMyCode, "myCode"));
		const onSuccess = res => {
			let messageSuccess = "";
			if (action === "restart") {
				messageSuccess = i18n.t("OPERATION.ACTION.restart") + ` Composant(s) : ${uniqueMyCode}`;
			}
			if (action === "stop") {
				messageSuccess = i18n.t("OPERATION.ACTION.stop") + ` Composant(s) : ${uniqueMyCode}`;
			}
			if (action === "clearCache") {
				messageSuccess = i18n.t("OPERATION.ACTION.clearCache") + ` Composant(s) : ${uniqueMyCode}`;
			}
			dispatch(
				display({
					message: messageSuccess,
					type: "success"
				})
			);
		};
		if (action === "restart") {
			dispatch(OperationActions.restartSeletedComponent(uniqueMyCode, onSuccess));
		}
		if (action === "stop") {
			dispatch(OperationActions.stopSeletedComponent(uniqueMyCode, onSuccess));
		}
		if (action === "clearCache") {
			dispatch(OperationActions.cleaCacheSelectedComponent(uniqueMyCode, onSuccess));
		}

		handleMenuClose();
	};

	const actionSaveOrCreateItem = (values, unique, callback) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setAddNewLabel(false);
			callback(true);
		};
		let data = { values, target: selectedTarget, unique, useLegacy, dbName };
		if (empty) {
			dispatch(
				FormActions.addItemEmpty(data, onSuccess, err => {
					callback(false);
				})
			);
		} else {
			if (data.target === "platformConfig") {
				data.values.last_update = new Date();
			}
			dispatch(
				FormActions.updateItem(selectedId, data, onSuccess, err => {
					callback(false);
				})
			);
		}
	};

	/**
	 * Charts loader
	 */
	if (chartsLoading) {
		return (
			<DashboardLayout>
				<MDBox py={3}>
					<DashboardNavbar />
					<ChartsLoader darkMode={darkMode} />
				</MDBox>
			</DashboardLayout>
		);
	} else
	/**
	 * Main component
	 */
		return (
			<DashboardLayout>
				<MDBox mb={3}>
					<DashboardNavbar
						filters={[
							<MDBox display="flex" alignItems="center">
								<ButtonNewLabel
									handleClick={() => {}}
									namePage={route?.form?.pageLabel ?? route.name}
								/>

								{attributeMyCode.length !== 0 && (
									<>
										<Badge badgeContent={attributeMyCode.length} color="info">
											<MDButton
												style={{ height: "100%", marginRight: "0.75rem" }}
												variant="contained"
												color="info"
												onClick={handleMenuClick}
											>
												ACTIONS
											</MDButton>
										</Badge>
										<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
											<MenuItem onClick={() => handleOptionClick("start")}>START</MenuItem>

											<MenuItem onClick={() => handleOptionClick("restart")}>RESTART</MenuItem>
											<MenuItem onClick={() => handleOptionClick("stop")}>STOP</MenuItem>
											<MenuItem onClick={() => handleOptionClick("clearCache")}>
												VIDER LE CACHE
											</MenuItem>
										</Menu>
									</>
								)}

								<MDBox display="flex">
									{parseFilters(route.route, pageFilters, filters, dispatch)}
								</MDBox>
							</MDBox>
						]}
					/>
				</MDBox>

				{confirmAction && (
					<Dialog open={confirmAction} onClose={closeActionHandle}>
						<DialogTitle>{i18n.t("OPERATION.LABELS.actionStatus")}</DialogTitle>
						<DialogContent>{getActionLabel(selectedAction)}</DialogContent>
						<DialogActions>
							<MDButton autoFocus onClick={closeActionHandle} variant="outlined" color="dark">
								{i18n.t("FORMS.cancel")}
							</MDButton>
							<MDButton
								onClick={() => actionComponent(selectedComponent, selectedAction)}
								color="info"
								variant="contained"
								autoFocus
							>
								{i18n.t("FORMS.validate")}
							</MDButton>
						</DialogActions>
					</Dialog>
				)}

				{/*
				 * Pagined table
				 */}
				{getChartsData()
					.filter(chart => chart.type === "paginedList")
					.map((chart, index) => {
						return (
							<MDBox
								className="pageContentContainer"
								style={{
									display: openPannel ? "flex" : "block"
								}}
							>
								<MDBox
									flex="1"
									style={{
										width: openPannel ? "auto" : "auto"
									}}
								>
									<DefaultDataTable
										reloadTable={reloadTable}
										dictionary={chart.dictionary}
										list={chart}
										pagination={chart.pagination}
										canSearch
										key={index}
										table={chart.data}
										collection={chart.request.collection}
										display={chart.request.attributesDisplay}
										actionViewHandle={items => actionViewHandle(items, chart.request.collection)}
										actionEditHandle={items =>
											actionEditHandle(items, chart.request.collection, true)
										}
										actionCheckHandle={(items, event) => actionCheckHandle(items, event)}
										attributeMyCode={attributeMyCode}
									/>
								</MDBox>
								{openPannel && (
									<MDBox className="rightPannelContainer" ref={rightPannelContainer}>
										<MDBox className="rightPannel" sx={{ ml: 2 }}>
											<Card sx={{ p: 2, height: "100%" }}>
												<MDBox display="flex" alignItems="center" justifyContent="space-between">
													<MDBox>Composant :</MDBox>
													<MDBox>
														<Tooltip placement="top" title="Fermer">
															<IconButton onClick={() => closePannel()}>
																<Icon>close</Icon>
															</IconButton>
														</Tooltip>
													</MDBox>
												</MDBox>
												{!lod_.isNil(selectedComponent) ? (
													<MDBox height="100%">
														{/* Topics */}
														<MDBox>
															<MDTypography variant="h6">{selectedComponent.mySelf}</MDTypography>
															<div>
																{componentStatus} Dernier Ping : {componentStatusTime}
															</div>
														</MDBox>
														{/* Sentiments */}
														<MDBox>
															<MDTypography variant="h6" mt={2}>
																Actions
															</MDTypography>
															{selectedComponent.myCode ? (
																<MDBox display="flex" flex>
																	<MDBox pl={1} mt={1}>
																		<MDButton color="info">
																			{" "}
																			<PlayCircleFilledWhite /> START ALL
																		</MDButton>
																	</MDBox>
																	<MDBox pl={1} mt={1}>
																		<MDButton
																			onClick={() => openActionHandle("restart")}
																			color="info"
																		>
																			<RestartAlt /> RESTART ALL
																		</MDButton>
																	</MDBox>
																	<MDBox pl={1} mt={1}>
																		<MDButton onClick={() => openActionHandle("stop")} color="info">
																			<StopCircle />
																			STOP ALL
																		</MDButton>
																	</MDBox>
																	<MDBox pl={1} mt={1}>
																		<MDButton
																			onClick={() => openActionHandle("clearCache")}
																			color="info"
																		>
																			Vider le cache
																		</MDButton>
																	</MDBox>
																</MDBox>
															) : (
																<MDTypography variant="h6">
																	{i18n.t("OPERATION.ACTION.noMyCode")}
																</MDTypography>
															)}
														</MDBox>
														{/* JSON */}
														<MDBox
															style={{
																height: "100%",
																overflowY: " auto"
															}}
														>
															<MDBox style={{ marginTop: "1rem" }}> JSON :</MDBox>
															<MDTypography variant="h6">
																{selectedJSON && Object.keys(selectedJSON).length !== 0 && (
																	<JSONTree data={selectedJSON} theme={{ base00: "none" }} />
																)}
															</MDTypography>
														</MDBox>
													</MDBox>
												) : (
													<MDBox
														style={{
															height: "100%"
														}}
														display="flex"
														justifyContent="center"
														alignItems="center"
													>
														<MDTypography variant="h6">
															Aucune information sur le composant
														</MDTypography>
													</MDBox>
												)}
											</Card>
										</MDBox>
									</MDBox>
								)}
								{addNewLabel && !lod_.isEmpty(valuesForm) && (
									<FormDictionaryDialog
										open={addNewLabel}
										route={route}
										handleCloseDialog={() => setAddNewLabel(false)}
										handleSave={(values, unique, callback) =>
											actionSaveOrCreateItem(values, unique, callback)
										}
										valuesDictionary={valuesForm}
										formBuildDictionary={formBuild}
										contextDictionary={contextDictionary}
										PIaccess={PI}
										isEmpty={empty}
										target={selectedTarget}
										selectedId={selectedId}
										routeDict={routeDict}
										listAPIs={listAPIs}
										useLegacy={useLegacy}
										dbName={dbName}
									/>
								)}
							</MDBox>
						);
					})}
			</DashboardLayout>
		);
}
