/* eslint-disable import/no-named-as-default */
import user from "./userReducers";
import profile from "./profileReducer";
import assistants from "./assistantsReducer";
import permissions from "./permissionsReducer";
import snackBar from "./snackBarReducer";
import error from "./errorReducer";
import filters from "./filtersReducers";
import notifications from "./notificationsReducer";

const rootReducer = {
	user,
	profile,
	assistants,
	permissions,
	snackBar,
	error,
	filters,
	notifications
};

export default rootReducer;
